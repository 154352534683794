import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  fab_styles: {
    "& .MuiSpeedDial-fab": {
      position: "fixed",
      bottom: "29px",
      zIndex: '1',
      background: "#F1B855",
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    },
    "& .MuiSpeedDialIcon-root": {
      color: "#FFFFFF",
    },
    "& .MuiSpeedDial-actions": {
      position: "fixed",
      bottom: "50px",
      marginBottom: "40px",
      zIndex: '1',
      transform: "scale3d(1.6, 1.6, 1.6)",
      "& .MuiSpeedDialAction-fab": {
        background: "linear-gradient(to bottom, #FFFFFF 65%, #F7F7F7 98%)",
        "-moz-box-shadow": "inset 0px 0px 0px 1.5px #F1B855",
        "-webkit-box-shadow": "inset 0px 0px 0px 1.5px #F1B855",
        boxShadow: "inset 0px 0px 0px 1.5px #F1B855",
      },
      "& .MuiSpeedDialAction-staticTooltip": {
        "& .MuiSpeedDialAction-staticTooltipLabel": {
          borderTopLeftRadius: "6px",
          borderBottomLeftRadius: "6px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          paddingBottom: "3px",
          width: "64px",
          whiteSpace: "nowrap",
          textAlign: "center",
          fontSize: ".6rem",
          marginRight: "-8px",
          color: "#444444",
          "-moz-box-shadow": "inset 0px 0px 0px 1.5px #F1B855",
          "-webkit-box-shadow": "inset 0px 0px 0px 1.5px #F1B855",
          boxShadow: "inset 0px 0px 0px 1.5px #F1B855",
        },
      },
    },
    "& .MuiSpeedDialAction-fab": {
      margin: "9px 0px",
    },
  },
}))
