import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  selectRoot: {
    "&.MuiInputBase-root": {
      "&.MuiOutlinedInput-root":{
        "& .MuiSelect-icon": {
          color: "rgba(68, 68, 68, 0.54)",
        }
      },
    "& .MuiInputBase-input": {
      "&.MuiOutlinedInput-input": {
        padding: "10.5px 11px",
        display: "inline-flex",
        alignItems: "center",
        color: "#44444499",
        background: "#FFFFFF",
        "& svg": {
          marginRight: "6px"
        },
      }
    }
  }
  },
  reviewsContainer: {
    paddingBottom: "58px",
    minWidth: "100%",
    position: "absolute",
    top: "62px"
  },
  reviewsSelectViewWrapper: {
    alignSelf: "center"
  },
  reviewsSelectViewFormControl: {
    margin: "8px",
    zIndex: '1'
  },
  reviewsSelectViewOptionsAllReviewsIconWrapper: {
    display:"flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "40px",
    height: "33px",
    marginRight: "4px"
  },
  reviewsSelectViewOptionsIconWrapper: {
    display:"flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "40px",
    height: "33px",
    marginLeft: "3px"
  },
  reviewCardRoot: {
    margin: "0 auto",
    marginTop: "15px",
    marginBottom: "19px",
    width: "95%",
    maxWidth: "950px",
    height: "min-content",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    background: "#FAFAFA",
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
  },
  reviewsCardHeader: {
    width: "100%",
    height: "105px",
    padding: "16px, 8px, 16px, 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  reviewsCardMedia: {
    width: "100%",
    height: "195px",
    borderRadius: "50%"
  },
  reviewsCardBody: {
    height: "min-content",
    padding: "0px 9px",
    fontSize: "14px",
    color: "#44444499"
  },
  cardMediaVolumeIcon: {
    width: "78px",
    height: "78px",
  },
  cardMedia: {
    width: "175px",
    height: "175px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(180deg, rgba(238, 171, 54, 0.86) 0%, rgba(215, 149, 46, 0.6) 50%, rgba(246, 197, 116, 0.86) 100%)",
    border: "2px solid rgba(68, 68, 68, 0.25)",
    borderRadius: "100px"
  },
  reviewVideo:{
    position: 'relative',
    width: '100%',
    paddingBottom: '1.5rem',
    textAlign: 'center'
  },
  muteVideoIcon:{
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90%',
    opacity: '.7',
    zIndex: '1',
    color: 'whitesmoke'

  },
  circleVideo:{
    borderRadius: '50%',
    objectFit: 'cover',
    margin:'0 auto',
    backgroundColor:'transparent!important',
    width: '250px!important',
    height: '250px!important',
    padding: '0!important',
    display: 'block'
},
  cardMediaIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  reviewsCardActions: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px",
  },
  reviewsLeftAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    width: "330px",
    height: "30px"
  },
  reviewsRightAction: {
    height: "35px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  leftActionBtn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    width: "73px",
    height: "30px",
    borderRadius: "4px",
    marginRight: "8px"
  },
  rightActionBtnEnabled: props => ({
    display: props.review.review_origin !== "facebook" ? "flex" : "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    width: "100px",
    height: "30px",
    background: "rgba(246, 197, 116, .3)",
    border: "1px solid rgb(246, 197, 116)",
    borderRadius: "4px",
  }),
  rightActionBtnDisabled: props => ({
    display: props.review.review_origin !== "facebook" ? "flex" : "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
  }),
  leftActionBtnContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    width: "63px",
    height: "22px"
  },
  rightActionBtnContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    width: "90px",
    height: "22px"
  },
  actionBtnText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "22px",
    letterSpacing: "0.46px",
    textTransform: "uppercase",
    color: "#EEAB36"
  },
  actionBtnIcon: {
    color: "#EEAB36"
  },
  headerIconButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
    marginRight: "8px",
    color: "rgba(68, 68, 68, 0.54)",
    "@media (max-width: 340px)": {
      marginRight: "3px",
    }
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    minWidth: "172px",
    width: "inherit",
    height: "83px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.4px",
    color: "rgba(68, 68, 68, 0.6)"
  },
  headerTextDate: {
    height: "20px"
  },
  headerTextBelowStars: {
    height: "20px",
    whiteSpace: "nowrap"
  },
  headerTextStars: {
    width: "108px",
    height: "19px"
  },
  headerTextCustomerName: {
    height: "24px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    color: "rgba(68, 68, 68, 0.87)"
  },
  headerAvatarWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "66px",
    height: "50px",
    justifyContent: "center",
    marginLeft: "15px",
    marginRight: "11px",
    "@media (max-width: 340px)": {
      marginLeft: "7px",
      marginRight: "7px",
    }
  },
  headerAvatar: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    fontSize: "20px"
  }
}));