import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

export const ChatViewsContainer = styled(Box)({
  backgroundColor: "#e5eaed", 
  borderRadius: "16px", 
  boxShadow: "inset 0 0 2.5px #4f6573",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "84px",
  width: "90%",
  maxWidth: "1000px",
  margin: "0 auto",
  padding: "0px 3px",
  marginBottom: "17px",
})

export const ChatViewButton = styled(Button)({
  fontSize: "0.875rem",
  height: "48px",
  width: "45%",
  borderRadius: "10px",
  "&.MuiButton-contained": {
    backgroundColor: "#FFF",
    boxShadow: "0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 1.5px 1.5px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%)", 
    color: "#444444",
    "&:hover": { 
      backgroundColor: "#f3f3f3", 
      color: "#222222" 
    },
    "&:focus": {
      backgroundColor: "#FFF",
      color: "#444444",
    },
    "&:active": {
      backgroundColor: "#FFF",
      color: "#444444",
    }
  },
  "&.MuiButton-text": {
    backgroundColor: "transparent",
    color: "rgba(68, 68, 68, 0.54)",
  },
  "& .MuiButton-startIcon": {
    marginRight: "3px",
  },
})

export const ChatConversationsContainer = styled(Box)({
  backgroundColor: "#e5eaed", 
  borderRadius: "16px", 
  boxShadow: "inset 0 0 2.5px #4f6573",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "102px",
  width: "96%",
  maxWidth: "1400px",
  margin: "0 auto",
})
