import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  ratingDisplay: {
    position: "fixed",
    height: "100vh",
    width: "325px",
    top: "27%",
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto"
  },
  writtenFormStyles: {
    padding: "0px",
    maxWidth: "850px",
    position: "absolute",
    top: "8%",
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        marginLeft: "9px",
        fontSize: ".85rem",
        "&:active": {
          color: "#3679ee",
        },
        "&.Mui-focused": {
          color: "#3679ee",
        },
      },
    },
    "& .MuiInputBase-root": {
      "&.MuiFilledInput-root": {
        borderRadius: "8px",
        backgroundColor: "rgba(196, 196, 196, 0.15)",
        border: "2px solid #e9e9e9",
        fontSize: ".9rem",
        color: "#444444",
        "&::before": {
          borderBottom: "none",
          borderRadius: "8px",
        },
        "&::after": {
          borderBottom: "none",
          borderRadius: "8px",
        },
        "&:active": {
          borderColor: "#3679ee",
        },
        "&.Mui-focused": {
          borderColor: "#3679ee",
        },
      },
    },
    "& .MuiSelect-select": {
      borderRadius: "8px",
    },
  },
  writtenVerificationBtn: {
    minWidth: "60px",
    width: "60px",
    height: "30px",
    color: "#8F8F8F",
    backgroundColor: "rgba(196, 196, 196, 0.15)",
    border: "none",
    outline: "2.5px solid #F1F1F1",
    borderRadius: "5px",
    fontSize: ".9rem",
    borderTopLeftRadius: "5px !important",
    borderTopRightRadius: "5px !important",
    borderBottomLeftRadius: "5px !important",
    borderBottomRightRadius: "5px !important",
    "&:hover": {
      backgroundColor: "rgba(196, 196, 196, 0.35)"
    },
    "&:nth-child(1)": {
      marginRight: "20px",
    },
    "&:nth-child(2)": {
      marginRight: "20px",
    },
    "&.Mui-selected": {
      background: "#3679ee",
      color: "#FFFFFF",
      fontWeight: "500",
      border: "none",
      outline: "2.5px solid #F1F1F1",
      "&:hover": {
        background: "#3347bc"
      }
    },
  },
  submitWrittenReviewBtn: {
    borderRadius: "8px",
    backgroundColor: "#3679ee",
    width: "90%",
    "&:hover": {
      backgroundColor: "#3347bc",
    },
  },
}))
