import { styled } from "@mui/material/styles";
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

export const BasicBottomPaper = styled(Paper)({
    zIndex: "1200",
    display: "flex",
    position: "fixed",
    bottom: "0px",
    left: "0px",
    right: "0px",
    flexDirection: "column",
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "#514F4F",
});
export const BasicContainer = styled(Box)({
    zIndex: "1200",
    filter: "none",
    borderTopLeftRadius: "18px",
    borderTopRightRadius: "18px"    
});

export const BasicBottomNavigation = styled(BottomNavigation)({
    borderTop: "1px solid rgba(0, 0, 0, 0.15)",
    borderTopLeftRadius: "18px",
    borderTopRightRadius: "18px",
    backgroundColor: "#FFFFFF",
    minHeight: "58px",
    height: "58px",
    maxHeight: "58px",
    zIndex: "1200",
    boxShadow: "none",
    "& a": {
        padding: "10px 0px 0px 0px",
        "&.Mui-selected": {
            padding: "0px"
        }
    }
});

export const BasicBottomNavBtn = styled(BottomNavigationAction)({
    color: "#514F4F",
    paddingBottom: "1px",
    "&.Mui-selected": {
        color: "#eebf3d",
    },
});
