import React, { useState, useEffect } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { HeaderTitle } from "@HelperFunctions"
import StuLogo from "@CompanyLogo"
import BackButton from "@BackBtn"
import { CorrectHeaderDisplay, BackBtn, ViewAppBar, ViewContainer, View } from "@AppHeaderStyles"
import PendingReviewsDialog from "../../components/PendingReviewsDialog.component";
import { useContext } from "react";
import { AppContext } from "../../utils/AppContext";

export default function Header() {
  const app = useContext(AppContext);
  let currentPage = useLocation()
  let path = currentPage.pathname
  let pr = JSON.parse(localStorage.getItem("PendingReviews"))
  const [pathname, setPathname] = useState("")
  const [backButton, toggleBackButton] = useState(true);

  
  const checkReviewCount = ()=>{
    let prs = JSON.parse(localStorage.getItem('PendingReviews'));
    for(let i in prs){
      if(prs[i].type === 'text')
      app.setPRWrittenCount(app.PRWrittenCount + 1);
    else if(prs[i].type === 'video')
      app.setPRVideoCount(app.PRVideoCount + 1);
    }
  }

  const browserCheck = ()=>{
    if(navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") > -1){
     app.setSafari(true);
    }
  }
  //does once every app launch
  useEffect(()=>{
    checkReviewCount();
    browserCheck();
  },[])
 
  useEffect(() => {
    getPath()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, path])

  function getPath() {
    if (path === "/" || path === "/dashboard") {
      toggleBackButton(false)
      setPathname(null)
    } else if (path === "/reviews" || path === "/gallery" || path === "/statistics" || path === "/chat" || path === "/settings") {
      toggleBackButton(true)
      setPathname("/dashboard")
    } else if (path === "/reviews/video" || path === "/reviews/written" || path === "/reviews/invite") {
      toggleBackButton(true)
      setPathname("/dashboard")
    } else {
      toggleBackButton(true)
      setPathname("/chat")
    }
  }

  const companyName = localStorage.getItem("companyname") ?? ""

  function AppHeader() {
    return (
      <>
        {path === "/" ? (
          <View component="div">
            <StuLogo />
          </View>
        ) : path === "/dashboard" ? (
          <View component="div">
            <StuLogo />
          </View>
        ) : (
          <div style={{position: "relative",  width: "100%"}}>
            {backButton ? <BackBtn id='back-button' to={pathname} component={RouterLink}><BackButton /></BackBtn> : null}
            <View component="div"><HeaderTitle /> {localStorage.getItem("companyname") ? "For "+companyName : ""}</View>
            {/*path === '/reviews/video' && app.PRVideoCount > 0 &&  <PendingReviewsDialog/>*/}
            {/*path === '/reviews/written' && app.PRWrittenCount > 0 && <PendingReviewsDialog/>*/}
          </div>
        )}
      </>      
    )
  }

  return (
    <ViewAppBar>
      <ViewContainer style={CorrectHeaderDisplay(pathname)}>
        <AppHeader />
      </ViewContainer>
    </ViewAppBar>
  )
}
