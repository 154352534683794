import React from 'react';

export default function UserSVG() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <defs>
                    <linearGradient id="userIconColor" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="65%" stopColor="#FFFFFF" />
                        <stop offset="98%" stopColor="#F7F7F7" />
                    </linearGradient>
                </defs>
                <path fill="#F1B855" d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                <path fill="url(#userIconColor)" d="M19.99 17.987C17.992 17.025 15.136 16 12 16s-5.991 1.025-7.99 1.987C5.834 20.417 8.731 22 12 22s6.165-1.582 7.99-4.013z" />
                <path d="M12 21.75a10.43 10.43 0 0 1-7.465-3.12C6.367 20.69 9.035 22 12 22s5.633-1.311 7.465-3.37A10.43 10.43 0 0 1 12 21.75z" opacity=".1" />
                <path fill="url(#userIconColor)" d="M12 .25c6.575 0 11.926 5.316 11.994 11.875 0-.042.006-.083.006-.125 0-6.617-5.383-12-12-12S0 5.383 0 12c0 .042.006.083.006.125C.074 5.566 5.426.25 12 .25z" opacity=".2" />
                <circle cx="12" cy="9" r="6" fill="url(#userIconColor)" />
                <path fill="url(#userIconColor)" d="M12 3.25c3.266 0 5.926 2.625 5.994 5.875 0-.042.006-.083.006-.125 0-3.309-2.691-6-6-6S6 5.691 6 9c0 .042.005.083.006.125C6.074 5.875 8.734 3.25 12 3.25z" opacity=".2" />
                <path d="M12 23.75C5.425 23.75.074 18.434.006 11.875c0 .042-.006.083-.006.125 0 6.617 5.383 12 12 12s12-5.383 12-12c0-.042-.006-.083-.006-.125C23.926 18.434 18.574 23.75 12 23.75z" opacity=".1" />
                <path d="M12 14.75c-3.266 0-5.926-2.625-5.994-5.875C6.006 8.917 6 8.958 6 9c0 3.309 2.691 6 6 6s6-2.691 6-6c0-.042-.005-.083-.006-.125-.068 3.25-2.728 5.875-5.994 5.875z" opacity=".1" />
                <path fill="url(#userIconColor)" d="M12 16c-3.135 0-5.991 1.025-7.99 1.987.045.06.089.123.136.183 1.992-.942 4.793-1.92 7.854-1.92s5.862.978 7.854 1.92c.047-.06.091-.122.137-.183C17.99 17.025 15.135 16 12 16z" opacity=".2" />
                <linearGradient id="a" x1="1.125" x2="22.875" y1="6.929" y2="17.071" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#FFF" stopOpacity=".2" />
                    <stop offset="1" stopColor="#FFF" stopOpacity="0" />
                </linearGradient>
                <path fill="url(#a)" d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
            </svg>
        </>
    );
}