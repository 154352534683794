import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"

import ReviewInviteAction from "@InviteAction"
import VideoReviewAction from "@VideoAction"
import WrittenReviewAction from "@WrittenAction"
// import AddToGalleryAction from "@GalleryAction"

import Backdrop from "@mui/material/Backdrop"
import SpeedDial from "@mui/material/SpeedDial"
import SpeedDialIcon from "@mui/material/SpeedDialIcon"
import SpeedDialAction from "@mui/material/SpeedDialAction"
import { useStyles } from "@SpeedDialStyles"

const actions = [
  {
    icon: (
      <RouterLink
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        to="/reviews/video"
      >
        <VideoReviewAction />
      </RouterLink>
    ),
    name: "Video",
    to: "/reviews/video"
  },
  {
    icon: (
      <RouterLink
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        to="/reviews/written"
      >
        <WrittenReviewAction />
      </RouterLink>
    ),
    name: "Written",
    to: "/reviews/written"
  },
  {
    icon: (
      <RouterLink
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        to="/reviews/invite"
      >
        <ReviewInviteAction />
      </RouterLink>
    ),
    name: "Invite",
    to: "/reviews/invite",
  },
  // GALLERY SPEED DIAL ACTION BUTTON (uncomment once Gallery section is active)
  // {
  //   icon: (
  //     <RouterLink
  //       style={{
  //         height: "100%",
  //         width: "100%",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //       to="/gallery"
  //     >
  //       <AddToGalleryAction />
  //     </RouterLink>
  //   ),
  //   name: "Gallery",
  //   path: "/gallery",
  // },
]

export default function BasicSpeedDial(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <Backdrop open={open} onClick={handleClose} style={{zIndex: '1'}} />

    </>
  )
}
