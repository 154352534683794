import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const DashboardLayout = styled(Box)({
  margin: '83px auto 58px auto',
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  padding: 20,
  height:"calc(100vh - 83px - 58px - 50px)",
});



export const DashboardBtn = styled(Button)({
  flex: '1 1 calc((100vh - 83px - 58px - 50px)/ 3)',
  height: 'calc((100vh - 83px - 58px - 50px) / 3)',
  listStyle: 'none',
  position: "relative",
  width: 250,
  background: "linear-gradient(to bottom, #FFFFFF 65%, #F7F7F7 98%)",
  boxShadow: "0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 2.5px 2.5px 0px rgb(0 0 0 / 14%), 0px 2px 6px 0px rgb(0 0 0 / 12%)",
  outline: "none",
  borderRadius: 10,
  padding: 10,
  margin: '5px auto',

});