export function setPageTitle(currentPath) {
    let chatInboxConversation = "/chat/inbox/conversation/"
    let chatClosedConversation = "/chat/closed/conversation/"

    if (currentPath === "/") {
        document.title = "SoTellUs App";
    } else if (currentPath === "/settings") {
        document.title = "My Settings";
    } else if (currentPath === "/dashboard") {
        document.title = "SoTellUs Dashboard";
    } else if (currentPath.includes(chatInboxConversation)) {
        document.title = "Inbox Conversation";
    } else if (currentPath.includes(chatClosedConversation)) {
        document.title = "Closed Conversation";
    } else if (currentPath === "/chat") {
        document.title = "SoTellUs Chat";
    } else if (currentPath === "/statistics") {
        document.title = "My Statistics";
    } else if (currentPath === "/reviews/video") {
        document.title = "Video Review";
    } else if (currentPath === "/reviews/written") {
        document.title = "Written Review";
    } else if (currentPath === "/reviews/invite") {
        document.title = "Review Invite";
    } else if (currentPath === "/gallery") {
        document.title = "My Gallery";
    }
}