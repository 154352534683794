import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { getReviews } from "./data/reviews.data";
import { setPageTitle } from "@CustomHooks";
import Login from "@Login";
import Dashboard from "@Dashboard";
import Reviews from "@Reviews";
import VideoReview from "@VideoReview";
import WrittenReview from "@WrittenReview";
import ReviewInvite from "@ReviewInvite";
import Chat from "@Chat";
import ClosedConversation from "@ClosedConvo";
import InboxConversation from "@InboxConvo";
import Stats from "@Stats";
import Settings from "@Settings";
import Header from "@AppHeader";
import BottomNav from "@BottomNav";
import BasicBottomNav from "@BasicBottomNav";
import SpeedDial from "@SpeedDial";

function App() {
  const location = useLocation();
  let path = location.pathname;
  let reviews = getReviews();
  let isMobile;

  const [width, setWidth] = useState(window.innerWidth);
  const [loggedIn, setLoggedIn] = useState(false);

  const checkStorage = () => {
    if (localStorage.getItem("AutoPostReviews") && localStorage.getItem("AutoPostReviews") === "")
      localStorage.removeItem("AutoPostReviews");

    if (localStorage.getItem("WiFiUploadsOnly") && localStorage.getItem("WiFiUploadsOnly") === "")
      localStorage.removeItem("WiFiUploadsOnly");

    if (localStorage.getItem("DefaultCountry") && localStorage.getItem("DefaultCountry") === "")
      localStorage.removeItem("DefaultCountry");

    if (localStorage.getItem("DisablePhoneValidation") && localStorage.getItem("DisablePhoneValidation") === "")
      localStorage.removeItem("DisablePhoneValidation");

    if (localStorage.getItem("DisablePhoneAutoFormat") && localStorage.getItem("DisablePhoneAutoFormat") === "")
      localStorage.removeItem("DisablePhoneAutoFormat");

    if (localStorage.getItem("ShowDebuggingMessages") && localStorage.getItem("ShowDebuggingMessages") === "")
      localStorage.removeItem("ShowDebuggingMessages");
  };

  useEffect(() => {
    if (localStorage.getItem("api_token")) {
      setLoggedIn(true);
      checkStorage();
    } else {
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    setPageTitle(path);
  }, [location, path]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (width <= 399) {
    isMobile = 2;
  } else if (width <= 899) {
    isMobile = 1;
  } else {
    isMobile = 0;
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  return (
    <>
      <Header />
      <Switch>
        {/*
        <Route path="/chat/closed/:conversation_id">
          {loggedIn ? <ClosedConversation /> : <Redirect push to="/" />}
        </Route>
        <Route path="/chat/inbox/:conversation_id">
          {loggedIn ? <InboxConversation /> : <Redirect push to="/" />}
        </Route>
        <Route path="/chat">
          {loggedIn ? (
            <Chat
              handleWindowSizeChange={handleWindowSizeChange}
              isMobile={isMobile}
            />
          ) : (
            <Redirect push to="/" />
          )}
          {loggedIn && <BasicBottomNav />}
        </Route>*/}
        <Route path="/reviews/invite">
          {loggedIn ? <ReviewInvite /> : <Redirect push to="/" />}
          {loggedIn && <BasicBottomNav />}
        </Route>
        <Route path="/reviews/video">
          {loggedIn ? <VideoReview /> : <Redirect push to="/" />}
          {loggedIn && <BasicBottomNav />}
        </Route>
        <Route path="/reviews/written">
          {loggedIn ? <WrittenReview /> : <Redirect push to="/" />}
          {loggedIn && <BasicBottomNav />}
        </Route>
        {/*
        <Route path="/reviews">
          {loggedIn ? <Reviews reviews={reviews} /> : <Redirect push to="/" />}
          {loggedIn && <SpeedDial isMobile={isMobile} />}
          {loggedIn && <BottomNav />}
        </Route>
        <Route exact path="/statistics">
          {loggedIn ? <Stats /> : <Redirect push to="/" />}
          {loggedIn && <BasicBottomNav />}
        </Route>*/}
        <Redirect exact from="/gallery" push to="/dashboard" />
        {/* <Route path="/gallery"><Gallery /><SpeedDial isMobile={isMobile} /><BottomNav /></Route> */}
        <Route exact path="/dashboard">
          {loggedIn ? <Dashboard /> : <Redirect push to="/" />}
          {loggedIn && <SpeedDial isMobile={isMobile} />}
          {loggedIn && <BottomNav />}
        </Route>
        <Route exact path="/settings">
          {loggedIn ? <Settings /> : <Redirect push to="/" />}
          {loggedIn && <BasicBottomNav />}
        </Route>
        <Route exact path="/">
          {loggedIn ? <Redirect to="/dashboard" /> : <Login />}
        </Route>
      </Switch>
    </>
  );
}

export default App;
