import React from "react"

export default function SingleStarSVG(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55"
        height="53"
        fill="none"
      >
        <path
          fill="url(#ratingStarFilled)"
          d="M53.517 23.8242c.6167-.4597 1.0742-1.1011 1.3082-1.8341.2341-.7331.233-1.5211-.003-2.2535-.236-.7324-.6952-1.3725-1.3131-1.8306-.6179-.4581-1.3636-.7112-2.1325-.7237l-14.9332-.5646c-.0735-.0051-.1439-.0313-.2027-.0755-.0589-.0442-.1038-.1045-.1292-.1736L30.9512 2.42043c-.2592-.70963-.7301-1.32239-1.3489-1.755321C28.9835.232181 28.2467 0 27.4917 0c-.755 0-1.4918.232181-2.1106.665109-.6188.432931-1.0897 1.045691-1.3489 1.755321L18.8885 16.4184c-.0254.0691-.0703.1294-.1292.1736-.0588.0442-.1292.0704-.2027.0755l-14.93322.5646c-.76888.0125-1.51456.2656-2.13248.7237-.617909.4581-1.077071 1.0982-1.3131 1.8306-.2360281.7325-.2370871 1.5204-.003028 2.2535.234059.733.691499 1.3744 1.308178 1.8342l11.71435 9.2157c.0586.0462.1024.1085.126.1794.0236.0708.0259.147.0067.2191L9.29802 47.7852c-.20894.7281-.18869 1.5028.05797 2.2189.24667.7162.70781 1.3388 1.32061 1.7834.6128.4445 1.3476.6894 2.1044.7012.7568.0118 1.4989-.21 2.1253-.6353l12.378-8.3024c.061-.0422.1333-.0647.2074-.0647s.1464.0225.2074.0647l12.378 8.3024c.6179.4398 1.3573.6761 2.1156.6761.7582 0 1.4976-.2363 2.1155-.6761.613-.4403 1.0746-1.06 1.3212-1.7736.2466-.7137.2662-1.4863.056-2.2116l-4.0652-14.3467c-.0215-.072-.0203-.1489.0034-.2202.0238-.0713.0689-.1336.1294-.1783l11.764-9.2988Z"
        />
        <defs>
          <linearGradient
            id="ratingStarFilled"
            x1="46.1882"
            x2="-24.1258"
            y1="4.29423"
            y2="94.9047"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEAB36" />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}
