import React, { useState } from "react"

import SingleStarSVG from "@SingleStarSvg"
import SingleStarOutlineSVG from "@SingleStarOutlineSvg"
import { useStyles } from "@RatingStyles"

import Rating from "@mui/material/Rating"

export default function StarRating({ handleRatingChange, value  }) {
  const [rating, setRating] = useState(0)
  const classes = useStyles()
  return (
    <>
      <Rating
        className={classes.starRating}
        name="star-rating"
        value={value ? value:rating}
        onChange={(event, newRating) => {
          setRating(newRating)
          handleRatingChange(newRating)
        }}
        icon={<SingleStarSVG />}
        emptyIcon={<SingleStarOutlineSVG />}
      />
    </>
  )
}
