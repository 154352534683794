import React, { useState, useEffect } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"

import ActiveHomeSVG from "@ActiveHomeSvg"
import InactiveHomeSVG from "@InactiveHomeSvg"
import ActiveSettingsSVG from "@ActiveSettingsSvg"
import InactiveSettingsSVG from "@InactiveSettingsSvg"

import {
  BasicBottomPaper,
  BasicContainer,
  BasicBottomNavigation,
  BasicBottomNavBtn,
} from "@BasicBottomNavStyles"

import Box from "@mui/material/Box"

export default function BasicBottomNav() {
  const [basicBottomNavStatus, setBasicBottomNavStatus] = useState("")

  const locationMap = [
    { path: "/dashboard", location: "dashboard" },
    { path: "/gallery", location: "gallery" },
    { path: "/reviews", location: "reviews" },
    { path: "/settings", location: "settings" },
  ]

  let currentPage = useLocation()
  let { pathname } = useLocation()

  useEffect(() => {
    const currentLocation = locationMap.find(
      (item) => item.path === currentPage.pathname
    )
    if (currentLocation && currentLocation.location !== "") {
      setBasicBottomNavStatus(currentLocation.location)
    } else {
      setBasicBottomNavStatus("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return (
    <BasicBottomPaper>
      <BasicContainer>
        <BasicBottomNavigation value={basicBottomNavStatus}>
          <BasicBottomNavBtn
            value="dashboard"
            icon={
              pathname === "/dashboard" ? (
                <ActiveHomeSVG />
              ) : (
                <InactiveHomeSVG />
              )
            }
            to="/dashboard"
            component={RouterLink}
          />
          <Box sx={{ flexGrow: 1.75 }} />
          <BasicBottomNavBtn
            value="settings"
            icon={
              pathname === "/settings" ? (
                <ActiveSettingsSVG />
              ) : (
                <InactiveSettingsSVG />
              )
            }
            to="/settings"
            component={RouterLink}
          />
        </BasicBottomNavigation>
      </BasicContainer>
    </BasicBottomPaper>
  )
}
