import * as React from "react"
function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'100%'}
      height={'100%'}
      fill="none"
      preserveAspectRatio="xMinYMid meet"
      viewBox="0 0 110 70"
    >
      <g filter="url(#filter0_i_19_257)">
        <path
          fill="url(#paint0_linear_19_257)"
          d="M106.431 16.687c-.988 0-1.886.391-2.582 1.104L93.837 28.44h-.157v26.84h.18l9.989 10.626c.696.712 1.594 1.127 2.582 1.127 1.975 0 3.569-1.656 3.569-3.68V20.344c0-2.024-1.594-3.657-3.569-3.657zm-26.064-2.99h-.697V3.783C79.67 1.697 78.014 0 75.958 0H51.365c-.971 0-1.866.448-2.475 1.229l-9.81 12.468H22.651c-4.871 0-8.822 4.048-8.822 9.039V60.96c0 4.99 3.95 9.039 8.822 9.039h57.717c4.871 0 8.822-4.048 8.822-9.039V22.736c0-4.991-3.95-9.04-8.823-9.04zM54.353 6.728c.38-.468.952-.76 1.561-.76H69.43c1.294 0 2.322 1.072 2.322 2.379v5.35h-22.87l5.472-6.969zm10.39 53.152h-41.06V32.257h41.06V59.88zm10.888-29.14c-2.223 0-4.019-1.863-4.019-4.14 0-2.277 1.796-4.117 4.019-4.117 2.222 0 4.04 1.84 4.04 4.117 0 2.276-1.818 4.14-4.04 4.14zM3.323 18.734C1.504 18.734 0 20.252 0 22.138v15.295c0 1.863 1.504 3.38 3.323 3.38.56 0 1.122-.137 1.571-.414l4.445-2.438V22.736c0-.368.022-.736.045-1.104L4.939 19.17a3.341 3.341 0 00-1.616-.437z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_i_19_257"
          width="115"
          height="70"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="6"></feOffset>
          <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_19_257"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_19_257"
          x1="17"
          x2="117.438"
          y1="57.037"
          y2="20.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.479" stopColor="#EEAB36"></stop>
          <stop offset="1" stopColor="#FFCC72"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;

