import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

export const SettingsItem = styled(Box)({
    color: "#444444",
    fontWeight: "700",
    width: "75%",
    "& .MuiTypography-root": {
        "&.MuiTypography-subtitle1": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "38px",
            minHeight: "38px",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "20px",
            "@media (max-width: 517px)": {
                fontSize: "17px",
            },
            lineHeight: "32.03px",
            fontFamily: "Roboto",
            color: "rgba(68, 68, 68, 0.87)"
        },
        "&.MuiTypography-caption": {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "13px",
            "@media (max-width: 359px)": {
                fontSize: "12px",
            },
            lineHeight: "175%",
            letterSpacing: "0.15px"
        },
    }
});

export const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiButtonBase-root": {
        "&.MuiSwitch-switchBase": {
            color: "#555",
            "&.Mui-checked": {
                color: "#EEAB36",
                "&:hover": {
                    "& .MuiTouchRipple-root": {
                        backgroundColor: "rgba(238, 171, 54, .4)",
                        zIndex: -1,
                    }
                }
            },
            "&:hover": {
                "& .MuiTouchRipple-root": {
                    backgroundColor: "rgba(85, 85, 85, .4)",
                    zIndex: -1,
                }
            }
        },
        "&.MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#555"
        },
        "&.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            background: "linear-gradient(42.41deg, #EEAB36 15.17%, #FFFFFF 228.89%)"
        },
    }
}));

export const SettingsBottom = styled(Box)({
    display: "flex",
    height: "75px",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiTypography-root": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "266%",
        letterSpacing: "1px",
        textTransform: "uppercase",
        color: "#222"
    }
});

export const CountryToggle = styled(Box)({
    width: "68px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    background: "#FFFFFF",
    border: "2px solid #555",
    borderRadius: "8px",
});