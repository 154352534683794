import * as React from "react"
function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'100%'}
      height={'100%'}
      preserveAspectRatio="xMinYMid meet"
      viewBox="0 0 110 54"
    >
      <g filter="url(#filter0_i_1504_11210)">
        <path
          fill="url(#paint0_linear_1504_11210)"
          d="M20.808 44.998H2.475c-.608 0-1.19.238-1.62.66a2.23 2.23 0 00-.672 1.59c0 .597.242 1.17.672 1.591.43.422 1.012.66 1.62.66h18.333c.608 0 1.191-.238 1.62-.66a2.23 2.23 0 00.672-1.59 2.23 2.23 0 00-.671-1.592 2.313 2.313 0 00-1.62-.658zM23.1 31.5H2.292c-.608 0-1.191.237-1.62.659A2.23 2.23 0 000 33.748c0 .598.241 1.17.671 1.592.43.422 1.013.659 1.62.659h20.81c.607 0 1.19-.237 1.62-.66a2.23 2.23 0 00.67-1.59 2.23 2.23 0 00-.67-1.591 2.314 2.314 0 00-1.62-.659zm2.338-13.5H2.292c-.608 0-1.191.238-1.62.66A2.23 2.23 0 000 20.248c0 .597.241 1.17.671 1.591.43.422 1.013.66 1.62.66h23.147c.607 0 1.19-.238 1.62-.66a2.23 2.23 0 00.671-1.59 2.23 2.23 0 00-.671-1.592 2.313 2.313 0 00-1.62-.659zM43.22 9.316c9.167 9 19.663 18.764 22.917 21.599a2.41 2.41 0 001.604.585c.273.046.552.046.825 0a5.912 5.912 0 001.375-.675c4.079-2.7 17.37-11.25 29.746-19.214l8.662-5.58c.284-.183.523-.426.699-.711a2.226 2.226 0 00.203-1.917 2.248 2.248 0 00-.535-.837 8.625 8.625 0 00-2.829-1.899A8.756 8.756 0 00102.529 0H44.963a9.276 9.276 0 00-5.73 1.935c-.243.211-.439.47-.572.762a2.215 2.215 0 000 1.851c.133.291.329.55.572.762l3.988 4.005z"
        ></path>
        <path
          fill="url(#paint1_linear_1504_11210)"
          d="M108.946 12.51a2.368 2.368 0 00-2.567 0l-5.179 3.33c-12.1 7.784-24.796 15.929-28.783 18.584a7.744 7.744 0 01-4.79 1.653 7.743 7.743 0 01-4.79-1.653c-3.162-2.88-13.75-12.87-22.916-21.78l-1.283-1.394a2.35 2.35 0 00-1.072-.575 2.38 2.38 0 00-1.22.035c-.4.114-.762.334-1.044.635a2.235 2.235 0 00-.56 1.074l-6.83 31.5a8.22 8.22 0 00.067 3.707 8.312 8.312 0 001.691 3.315 8.506 8.506 0 002.984 2.273 8.66 8.66 0 003.692.784h57.567a9.277 9.277 0 005.898-1.953 8.979 8.979 0 003.268-5.202l6.875-31.994a2.208 2.208 0 00-.135-1.323 2.26 2.26 0 00-.873-1.017zM2.292 9h25.437c.608 0 1.19-.237 1.62-.66a2.23 2.23 0 00.672-1.59 2.23 2.23 0 00-.671-1.591 2.314 2.314 0 00-1.62-.66H2.291c-.608 0-1.191.238-1.62.66A2.23 2.23 0 000 6.749c0 .597.241 1.17.671 1.592.43.422 1.013.659 1.62.659z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_i_1504_11210"
          width="115"
          height="54"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="6"></feOffset>
          <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="shape"
            result="effect1_innerShadow_1504_11210"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_1504_11210"
          x1="17"
          x2="110"
          y1="44"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.479" stopColor="#EEAB36"></stop>
          <stop offset="1" stopColor="#FFCC72"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1504_11210"
          x1="17"
          x2="110"
          y1="44"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.479" stopColor="#EEAB36"></stop>
          <stop offset="1" stopColor="#FFCC72"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;

