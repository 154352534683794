import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  messageContainer: props => ({
    display: "flex",
    padding: "11px 5px",
  }),
  message: props => ({
      textAlign: !props.msg.sent_by_customer ? "right" : "left",
      width: "100%",
      position: "relative",
      marginBottom: "8px",
  }),
  messageAvatar: props => ({
      display: "flex",
      alignItems: props.msg.sent_by_customer ? "center" : null,
      alignSelf: "center",
      justifyContent: props.msg.sent_by_customer ? "center" : null,
      marginRight: props.msg.sent_by_customer ? "16px" : "0px",
      marginLeft: props.msg.sent_by_customer ? "0px" : "16px",
      marginTop: "5px",
      minWidth: props.msg.sent_by_customer ? "33px" : null,
      width: props.msg.sent_by_customer ? "33px" : null,
      height: props.msg.sent_by_customer ? "33px" : null,
      borderRadius: props.msg.sent_by_customer ? "50%" : null,
      background: props.msg.sent_by_customer ? "#2196F3" : null,
  }),
  messageAvatarText: {
      color: "#FFFFFF", 
      fontSize: "1rem",
  },
  messageBubble: props => ({
      textAlign: "left",
      color: !props.msg.sent_by_customer ? "#222222" : "#FFF",
      background: !props.msg.sent_by_customer ? "#eff2f4" : "#2196F3",
      borderRadius: !props.msg.sent_by_customer ? "10px" : "10px",
      padding: "10px 14px",
      display: "inline-block",
      width: "85%",
      overflowWrap: "break-word",
      lineHeight: "1.4",
      fontSize: "13.5px",
      position: "relative",
      marginBottom: "2px",
      "&:after": {
        content: "''",
        position: "absolute",
        top: "50%",
        width: 0,
        height: 0,
        border: "20px solid transparent",
        borderBottom: 0,
        marginTop: "-10px",
        left: props.msg.sent_by_customer ? 0 : null,
        right: !props.msg.sent_by_customer ? 0 : null,
        borderRightColor: props.msg.sent_by_customer ? "#2196F3" : null,
        borderLeftColor: !props.msg.sent_by_customer ? "#eff2f4" : null,
        borderLeft: props.msg.sent_by_customer ? 0 : null,
        borderRight: !props.msg.sent_by_customer ? 0 : null,
        marginLeft: props.msg.sent_by_customer ? "-14px" : null,
        marginRight: !props.msg.sent_by_customer ? "-14px" : null,
      }
    }),
    timeStamp: props => ({
      position: "relative",
      fontSize: "11.5px",
      lineHeight: "1.25",
      color: "#63727D",
      margin: !props.msg.sent_by_customer ? "1px 2px 0px 0px" : "1px 0px 0px 2px",
    }), 
}))