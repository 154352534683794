import * as React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AppContext } from "../utils/AppContext";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import { yellow, dark } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

let pending_reviews = [{}];
if (localStorage.getItem("PendingReviews") != null)
  pending_reviews = JSON.parse(localStorage.getItem("PendingReviews"));

if (pending_reviews.length === 1) pending_reviews = pending_reviews[0];

function timeConverter(unix) {
  return new Date(unix).toLocaleDateString("en-US");
}

function PendingReviewsDialogRaw(props) {
  let history = useHistory();
  const app = useContext(AppContext);
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  let path = useLocation().pathname;

  React.useEffect(() => {
    if (localStorage.getItem("PendingReviews") != null)
      pending_reviews = JSON.parse(localStorage.getItem("PendingReviews"));
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("PendingReviews") != null)
      pending_reviews = JSON.parse(localStorage.getItem("PendingReviews"));
  }, [app.PRWrittenCount]);
  React.useEffect(() => {
    if (localStorage.getItem("PendingReviews") != null)
      pending_reviews = JSON.parse(localStorage.getItem("PendingReviews"));
  }, [app.PRVideoCount]);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const isPath = (askPath) => {
    if (path === `/reviews/${askPath}`) return true;
    return false;
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    //handle adding review

    Object.keys(pending_reviews).forEach((i) => {
      if (
        pending_reviews[i].PRID.toString() === value &&
        pending_reviews[i].type === "text"
      ) {
        history.replace(`/reviews/written#${value}`, {});
      } else if (
        pending_reviews[i].PRID.toString() === value &&
        pending_reviews[i].type === "video"
      ) {
        history.replace(`/reviews/video#${value}`, {});
      }
    });
    app.setReviewID(parseInt(value));
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Pending Reviews</DialogTitle>
      <DialogContent dividers>
        <RadioGroup ref={radioGroupRef} value={value} onChange={handleChange}>
          {Object.keys(pending_reviews).map((option) => {
            if (isPath("video")) {
              if (pending_reviews[option].type === "video") {
                return (
                  <FormControlLabel
                    value={pending_reviews[option].PRID}
                    key={pending_reviews[option].PRID}
                    control={<Radio />}
                    label={`REVIEW: ${timeConverter(
                      pending_reviews[option].PRID
                    )} | ${pending_reviews[option].firstname}`}
                  />
                );
              }
            } else if (isPath("written")) {
              if (pending_reviews[option].type === "text") {
                return (
                  <FormControlLabel
                    value={pending_reviews[option].PRID}
                    key={pending_reviews[option].PRID}
                    control={<Radio />}
                    label={`REVIEW: ${timeConverter(
                      pending_reviews[option].PRID
                    )} | ${pending_reviews[option].firstname} ${
                      pending_reviews[option].lastname
                    } | ${pending_reviews[option].mobile}`}
                  />
                );
              }
            }
          })}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

PendingReviewsDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function PendingReviewsDialog() {
  const app = useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("-1");
  const [tooltipOpen, setTooltipOpen] = React.useState(true);

  React.useEffect(()=>{
    setTimeout(()=>{
      setTooltipOpen(false);
    }, 3000)
  },[])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
      app.setReviewID(newValue);
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#333",
      fontSize: theme.typography.pxToRem(16),
    },
  }));
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {JSON.stringify(pending_reviews) !== JSON.stringify([{}]) && (
        <BootstrapTooltip title="You have pending reviews" open={tooltipOpen}>
          <Button
            onClick={handleOpen}
            style={{
              backgroundColor: yellow[500],
              height: "30px",
              width: "30px",
              minWidth: "30px",
              position: "absolute",
              top: "0px",
              right: "30px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ><PriorityHighRoundedIcon sx={{ color: "gray" }} /></Button>
        </BootstrapTooltip>
      )}
      {JSON.stringify(pending_reviews) !== JSON.stringify([{}]) && (
        <PendingReviewsDialogRaw
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
        />
      )}
    </Box>
  );
}
