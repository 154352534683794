import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    inviteFormStyles: {
        maxWidth: "850px",
        position: "fixed",
        top: "8%",
        bottom: 0,
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0px",
        "& .MuiFormControl-root": {
            "& .MuiFormLabel-root": {
                marginLeft: "9px",
                fontSize: ".85rem",
                "&:active": {
                    color: "#019d8e"
                },
                "&.Mui-focused": {
                    color: "#019d8e"
                }
            },
        },
        "& .MuiInputBase-root": {
            "&.MuiFilledInput-root": {
                borderRadius: "8px",
                backgroundColor: "rgba(196, 196, 196, 0.15)",
                border: "2px solid #e9e9e9",
                fontSize: ".9rem",
                color: "#444444",
                "&::before": {
                    borderBottom: "none",
                    borderRadius: "8px",
                },
                "&::after": {
                    borderBottom: "none",
                    borderRadius: "8px",
                },
                "&:active": {
                    borderColor: "#019d8e"
                },
                "&.Mui-focused": {
                    borderColor: "#019d8e"
                }
            }
        },
        "& .MuiSelect-select": {
            borderRadius: "8px"
        }
    },
    inviteTypeBtn: {
        color: "#8F8F8F",
        backgroundColor: "rgba(196, 196, 196, 0.15)",
        border: "none",
        outline: "2.5px solid #F1F1F1",
        borderRadius: "5px",
        fontSize: ".9rem",
        padding: "4px 8px",
        borderTopLeftRadius: "5px !important",
        borderTopRightRadius: "5px !important",
        borderBottomLeftRadius: "5px !important",
        borderBottomRightRadius: "5px !important",
        "&:hover": {
            background: "rgba(196, 196, 196, 0.35)"
        },
        "&:nth-child(1)": {
            marginRight: "20px"
        },
        "&:nth-child(2)": {
            marginRight: "20px"
        },
        "&.Mui-selected": {
            background: "#019d8e",
            color: "#FFFFFF",
            fontWeight: "500",
            border: "none",
            outline: "2.5px solid #F1F1F1",
            "&:hover": {
                background: "#006F61"
            }
        },
    },
    sendReviewInviteBtn: {
        borderRadius: "8px",
        backgroundColor: "#019d8e",
        width: "90%",
        "&:hover": {
            backgroundColor: "#006f61"
        }
    }
}));