import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"
import { RecoilRoot } from "recoil"
import { theme } from "@GlobalStyles"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import { AppProvider } from "./utils/AppContext";
import "@IndexStyles"

const root = document.getElementById("root")

ReactDOM.render(

  <AppProvider>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <RecoilRoot>

        <App />
      </RecoilRoot> 
    </Router>
  </ThemeProvider>
  </AppProvider>,
    root
)