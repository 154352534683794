import React, { Component } from "react";
import axios from "axios";
import Container from "@mui/material/Container"
import { useStyles } from "@ReviewsStyles"
import Review from '@Review'

class ReviewsScrollComponent extends Component {
  constructor() {
    super();
    this.state = { 
      reviews: [],
      loading: false,
      page: 0,
      prevY: 0
    };
  }

  componentDidMount() {
    this.getReviews(this.state.page);

    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      const lastReview = this.state.reviews[this.state.reviews.length - 1];
      let curPage = this.state.page;
      curPage = curPage + 1;
      this.getReviews(curPage);
      this.setState({ page: curPage });
    }
    this.setState({ prevY: y });
  }

  //*!API - get page amount first
  getReviews(page) {
    let api_token = localStorage.getItem("api_token");
    this.setState({ loading: true });
    axios
      .get(
        process.env.REACT_APP_API_URL + `reviews/?api_token=${api_token}&page=${page}`
      )
      .then(res => {
        let result = res.data;
        this.setState({ reviews: [...this.state.reviews, ...result.data.reviews] });
        this.setState({ loading: false });
        this.setState({page: result.data.current_page});
      });
  }






  render() {
    // Additional css
    const loadingCSS = {
      height: "100px",
      margin: "30px"
    };
    // To change the loading icon behavior
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };

    const facebookReviews = this.state.reviews.filter(function (e) {
      return e.system === "facebook" || e.system === "fbp" || e.system === "FBP";
    });
  
    const googleReviews = this.state.reviews.filter(function (e) {
      return e.system === "google" || e.system === "gmb" || e.system === "GMB";
    });
  
    const sotellusReviews = this.state.reviews.filter(function (e) {
      return e.system === "sotellus" || e.system === "stu" || e.system === "STU";
    });

    //*!RENDERED
    return (
      <Container fixed style={{paddingBottom: "58px",minWidth: "100%",position: "absolute",top: "px"}}>

        {this.props.view === "google" ?
            googleReviews.map((review) => { return (<Review key={review.id} review={review} />) })
         : this.props.view  === "facebook" ?
            facebookReviews.map((review) => { return (<Review key={review.id} review={review} />) })
         : this.props.view  === "sotellus" ?
            sotellusReviews.map((review) => { return (<Review key={review.id} review={review} />) })
         :
         this.state.reviews.map(review => {return (<Review key={review.id} review={review} />) })
        }
       


        <div
          ref={loadingRef => (this.loadingRef = loadingRef)}
          style={loadingCSS} >
          <span style={loadingTextCSS}>Loading...</span>
        </div>
      </Container>
    );
  }
}

export default ReviewsScrollComponent;