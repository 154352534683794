import React from "react"

export default function ActiveHomeSVG() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="42" fill="none">
        <g clipPath="url(#actHme1)">
          <g filter="url(#actHme2)">
            <path fill="#F1B855" d="M1.72396 12.355C1.26539 12.7349 1 13.2995 1 13.895V28.3548C1 29.4594 1.89543 30.3548 3 30.3548H9C10.1046 30.3548 11 29.4594 11 28.3548V22.6215C11 20.486 12.7909 18.7548 15 18.7548C17.2091 18.7548 19 20.486 19 22.6215V28.3548C19 29.4594 19.8954 30.3548 21 30.3548H27C28.1046 30.3548 29 29.4594 29 28.3548V13.895C29 13.2995 28.7346 12.7349 28.276 12.355L16.276 2.41212C15.5359 1.79884 14.4641 1.79885 13.724 2.41212L1.72396 12.355Z"/>
          </g>
          <g filter="url(#actHme3)">
            <path fill="#F1B855" d="M26 39.5238C26 38.8334 25.4404 38.2738 24.75 38.2738H5.25C4.55964 38.2738 4 38.8334 4 39.5238V39.5238C4 40.2141 4.55964 40.7738 5.25 40.7738H24.75C25.4404 40.7738 26 40.2141 26 39.5238V39.5238Z"/>
          </g>
        </g>
        <defs>
          <filter id="actHme2" width="36" height="36.4027" x="-3" y="1.95216" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_7"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_2_7" result="shape"/>
          </filter>
          <filter id="actHme3" width="30" height="10.5" x="0" y="38.2738" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_7"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_2_7" result="shape"/>
          </filter>
          <clipPath id="actHme1">
            <path fill="#fff" d="M0 0H30V42H0z"/>
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
