import React from "react";

export default function InactiveHomeSVG() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" fill="none">
        <path fill="#8F8F8F" d="M1.71362 8.80056C1.26122 9.18057 1 9.74116 1 10.332V20c0 1.1046.89543 2 2 2h3.14286c1.10457 0 2-.8954 2-2v-3.6c0-1.5464 1.27918-2.8 2.85714-2.8 1.578 0 2.8571 1.2536 2.8571 2.8V20c0 1.1046.8955 2 2 2H19c1.1046 0 2-.8954 2-2v-9.668c0-.59084-.2612-1.15143-.7136-1.53144l-8-6.72c-.7438-.62479-1.829-.62478-2.57278 0l-8 6.72Z"/>
      </svg>
    </>
  );
}
