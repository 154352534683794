import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import UserSVG from "@UserSvg";
import {
  LoginViewContainer,
  UserAvatar,
  LoginFormLayout,
  LoginCheckbox,
  LoginBtn,
} from "@LoginStyles";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { MutatingDots } from  'react-loader-spinner'

export default function Login() {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [error, setError] = useState("");
  const [login, setLogin] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState({
    showPassword: false,
  });

  useEffect(()=>{
    setError(false)
  }, [username,password])

  const [snackbarMsg, setSnackbarMsg] = useState("Incorrect login");
  const [snackbarState, setSnackBarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = snackbarState;

  const handleClose = () => {
    setSnackBarState({ ...snackbarState, open: false });
  };
  const handleClickShowPassword = () => {
    setPasswordVisibility({ showPassword: !passwordVisibility.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUsernameError(false);
    setPasswordError(false);
    setLogin(true);

    let submitted_username;

    if (username !== "") {
      submitted_username = username.toLowerCase();
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Cookie", "PHPSESSID=61jpomouor88dkvi0vdlj6j28t");

    var urlencoded = new URLSearchParams();
    urlencoded.append("username", submitted_username);
    urlencoded.append("password", password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    try {
      let res = await fetch(
        process.env.REACT_APP_API_URL + "auth",
        requestOptions
      );
      let result = await res.json();
      if (result.status === "success") {
        var api_token = result.data.api_token;
        const mydashboard = await fetch(process.env.REACT_APP_API_URL_2 + "client?api_token="+api_token, {method: "GET" })
        const userdata = await mydashboard.json()

        if(userdata.status==="success") {

          localStorage.setItem("service_review", userdata.data.clientinfo.service_review)
          localStorage.setItem("companyname", userdata.data.clientinfo.companyname)
          localStorage.setItem("logo_url", userdata.data.clientinfo.logo_url)
          localStorage.setItem("hipaa",  userdata.data.clientinfo.hipaa);
          localStorage.setItem("api_token", result.data.api_token);
          if (localStorage.getItem("api_token").length > 0) {
            setSnackBarState({
              open: true,
              vertical: "bottom",
              horizontal: "center",
              severity: 'success'
            });
            history.push("/dashboard");
            history.go(0);
          }
        }else{
          setSnackBarState({
            open: true,
            vertical: "bottom",
            horizontal: "center",
            severity: 'error'
          });
          setLogin(false);
        }
      } else {
        setError("Error, login is incorrect!")
        setSnackBarState({
          open: true,
          vertical: "bottom",
          horizontal: "center",
          severity: 'error'
        });
        setLogin(false);
      }
    } catch (e) {
      console.error(e);
      setLogin(false);
    }

    /*
    fetch("https://beta.sotellus.com/API/v1/auth", requestOptions)
    .then((response) => { if (!response.ok) { throw Error(response.statusText) } return response.json() })
      .then((result) => { localStorage.setItem("api_token", result.data.api_token) })
      .then(() => { if (localStorage.getItem("api_token").length) { history.push("/dashboard") } })
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
      */
  };


  function LoadingContainer(){
    return(
      <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'100vh'}}>
        <div style={{backgroundColor: 'rgba(255,255,255,.5)',filter:'blur(50px)', position: 'absolute', zIndex: 9, height:'100vh', width: '100vw', }}></div>
        <div style={{fontSize: 22, zIndex:10, position: 'relative', backgroundColor: 'white', padding: 10, borderRadius: '10px', border: '1px solid #eee', width: 250,textAlign:'center', margin: '0 auto' }}> <MutatingDots height="100" width="100" color="#f0b144" secondaryColor= '#f0b144' radius='12.5' ariaLabel="mutating-dots-loading" wrapperStyle={{justifyContent: 'center'}}wrapperClass=""visible={true}/>Signing In...</div>
      </div>
    )
  }

  return (
    <>
      {login && <LoadingContainer/> }
      <CssBaseline />
      <Container fixed>
        <LoginViewContainer
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >

          <LoginFormLayout elevation={0}>
            {error !== '' ?
                <Alert severity={'error'}>{error}</Alert>
                : <></>}
            <UserAvatar>
              <UserSVG />
            </UserAvatar>
            <Grid container direction="row">
              <Grid item xs={12} sx={{ paddingTop: "12px" }}>
                <TextField
                  autoFocus
                  id="username"
                  type="text"
                  label="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete="off"
                  error={usernameError}
                  sx={{ width: "92%" }}
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&nbsp;</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                <TextField
                  id="user-password"
                  type={passwordVisibility.showPassword ? "text" : "password"}
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ width: "92%" }}
                  autoComplete="current-password"
                  error={passwordError}
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&nbsp;</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ color: "#AEAEAE" }}
                        >
                          {passwordVisibility.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  paddingTop: "6px",
                  "& .MuiFormControlLabel-root": {
                    "& .MuiFormControlLabel-label": {
                      fontSize: ".8rem",
                      color: "rgba(0, 0, 0, 0.6)",
                    },
                  },
                }}
              >
                <FormControlLabel
                  sx={{ width: "92%", marginRight: "0px", textAlign: "start" }}
                  control={<LoginCheckbox value="remember" />}
                  label="Remember me"
                />
              </Grid>
              {usernameError && !passwordError ? (
                <Grid item xs={12} sx={{ padding: "10px 0px" }}>
                  <Stack sx={{ width: "90%", margin: "0 auto" }} spacing={2}>
                    <Alert
                      severity="error"
                      sx={{ border: "1px solid #F44336" }}
                    >
                      Invalid Username.
                    </Alert>
                  </Stack>
                </Grid>
              ) : null}
              {passwordError && !usernameError ? (
                <Grid item xs={12} sx={{ padding: "10px 0px" }}>
                  <Stack sx={{ width: "90%", margin: "0 auto" }} spacing={2}>
                    <Alert
                      severity="error"
                      sx={{ border: "1px solid #F44336" }}
                    >
                      Invalid Password.
                    </Alert>
                  </Stack>
                </Grid>
              ) : null}
              {usernameError && passwordError ? (
                <Grid item xs={12} sx={{ padding: "10px 0px" }}>
                  <Stack sx={{ width: "90%", margin: "0 auto" }} spacing={2}>
                    <Alert
                      severity="error"
                      sx={{ border: "1px solid #F44336" }}
                    >
                      Invalid credentials.
                    </Alert>
                  </Stack>
                </Grid>
              ) : null}
              <Grid item xs={12} sx={{ padding: "6px 0px" }}>
                <LoginBtn type="submit" fullWidth={true} variant="contained">
                  Sign In
                </LoginBtn>
              </Grid>
            </Grid>
          </LoginFormLayout>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {snackbarMsg}
            </Alert>
          </Snackbar>


        </LoginViewContainer>
      </Container>
    </>
  );
}
