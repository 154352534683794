import { styled } from "@mui/material/styles"
import Button from "@mui/material/Button"

export const ChatClosedConvoBtns = styled(Button)({
  margin: "11px 0px",
  borderRadius: "8px",
  fontSize: "0.875rem",
  height: "40px",
  width: "42%",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 1.5px 1.5px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%)",  
  "&.MuiButton-contained": {
    color: "#222222",
  },
  "&.MuiButton-text": {
    backgroundColor: "transparent",
    color: "#222222",
  },
  "& .MuiButton-startIcon": {
    marginRight: "3px",
  },
  "&:hover": { 
    backgroundColor: "#f3f3f3", 
    color: "#111111" 
  },
  "&:focus": {
    backgroundColor: "#FFF", 
  },
  "&:active": {
    backgroundColor: "#FFF", 
  }  
})
