import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(',')
  },
  background: {
    default: '#FAFDFF',
  },
  palette: {
    default: '#eba834',
  }
});