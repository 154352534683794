import React, {useEffect} from "react"
import {Link as RouterLink} from "react-router-dom"
import { Typography, Stack} from '@mui/material';

import ReviewsSVG from "@ReviewsSvg"
import VideoSVG from "@VideoReviewIcon"
import WrittenSVG from "@WrittenReviewIcon"
import InviteSVG from "@InviteReviewIcon"

import GallerySVG from "@GallerySvg"
import ChatSVG from "@ChatSvg"
import StatsSVG from "@StatsSvg"

import {DashboardLayout, ButtonContainer, ButtonWrapper, DashboardBtn} from "@DashboardStylesJS"
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

function pwaTrackingListeners(){
    const fireTracking = (label) => {
        //Fire tracking here
    };

    const pwaTrackingListeners = () => {
        const fireAddToHomeScreenImpression = (event) => {
            fireTracking("Add to homescreen shown");
            //will not work for chrome, untill fixed
            event.userChoice.then((choiceResult) => {
                fireTracking(`User clicked ${choiceResult}`);
            });
            //This is to prevent `beforeinstallprompt` event that triggers again on `Add` or `Cancel` click
            window.removeEventListener(
                "beforeinstallprompt",
                fireAddToHomeScreenImpression
            );
        };
        window.addEventListener("beforeinstallprompt", fireAddToHomeScreenImpression);

        //Track web app install by user
        window.addEventListener("appinstalled", (event) => {
            fireTracking("PWA app installed by user!!! Hurray");
        });

        //Track from where your web app has been opened/browsed
        window.addEventListener("load", () => {
            let trackText;
            if (navigator && navigator.standalone) {
                trackText = "Launched: Installed (iOS)";
            } else if (matchMedia("(display-mode: standalone)").matches) {
                trackText = "Launched: Installed";
            } else {
                trackText = "Launched: Browser Tab";
            }
            fireTracking(trackText);
        });
    };
}

export default function Dashboard() {

    const service_review = localStorage.getItem("service_review") ?? 0

    useEffect(()=>{

        const isBrowser = typeof window !== "undefined";

        if (isBrowser) {
            pwaTrackingListeners();
        }

        if (isBrowser && "serviceWorker" in navigator) {
            window.addEventListener("load", () => {
                navigator.serviceWorker
                    .register("/service-worker.js")
                    .then(() => {
                    })
                    .catch((err) => {
                        console.log("Service worker registration failed", err);
                    });
            });
        }
    }, [])


    if(service_review>=5) {
        return (
            <DashboardLayout>

                        <DashboardBtn id="writtenreviewsBtn" className="dashBtn" to="/reviews/written"
                                      component={RouterLink}>
                            <Stack spacing={0} style={{justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{padding: '0 30%'}}><WrittenSVG/></div>
                                <Typography align='center' style={{fontSize: '150%', color: 'black', padding: 10}}>Written
                                    Reviews</Typography>
                            </Stack>
                        </DashboardBtn>

                {service_review >= 40 ?

                            <DashboardBtn id="videoreviewsBtn" className="dashBtn" to="/reviews/video"
                                          component={RouterLink}>
                                <Stack spacing={0} style={{justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{padding: '0 25%'}}><VideoSVG/></div>
                                    <Typography align='center' style={{fontSize: '150%', color: 'black', padding: 0}}>Video
                                        Reviews</Typography>
                                </Stack>

                            </DashboardBtn>

                    : <></>}

                        <DashboardBtn id="videoreviewsBtn" className="dashBtn" to="/reviews/invite"
                                      component={RouterLink}>
                            <Stack spacing={0} style={{justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{padding: '0 30%'}}><InviteSVG/></div>
                                <Typography align='center' style={{fontSize: '150%', color: 'black', margin: 0}}>Send
                                    Invite</Typography>
                            </Stack>

                        </DashboardBtn>


                {/* REVIEWS SECTION */}
                {/*  <ButtonContainer>
              <ButtonWrapper>
                  <DashboardBtn
                    id="reviewsBtn"
                    className="dashBtn"
                    to="/reviews"
                    component={RouterLink}
                  >
                    <ReviewsSVG />

                  </DashboardBtn>
                </ButtonWrapper>



           <ButtonWrapper>
                  <DashboardBtn
                    id="galleryBtn"
                    className="dashBtn"
                    to="/gallery"
                    component={RouterLink}
                    disabled
                  >
                    <GallerySVG />
                  </DashboardBtn>
                </ButtonWrapper>
              </ButtonContainer>

              <ButtonContainer>
                <ButtonWrapper>
                  <DashboardBtn className="dashBtn" to="/chat" component={RouterLink}>
                    <ChatSVG />
                  </DashboardBtn>
                </ButtonWrapper>
                <ButtonWrapper>
                  <DashboardBtn
                    className="dashBtn"
                    to="/statistics"
                    component={RouterLink}
                  >
                    <StatsSVG />
                  </DashboardBtn>
                </ButtonWrapper>
              </ButtonContainer>*/}

            </DashboardLayout>
        )
    }else if(service_review<5){
        return (
            <DashboardLayout>
                <Alert variant={'filled'} color={'info'}>
                To access the WebApp you need to upgrade your account
            <br/><a href={"tel:4808325511"}> Call us</a> to upgrade your account
            </Alert>
        </DashboardLayout>
    )
    }
}
