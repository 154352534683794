import React from "react";
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"

export const LoginViewContainer = styled(Box)({
  flexGrow: 1,
  textAlign: "center",
  padding: "0px",
  margin: "0px 15px",
  position: "fixed",
  height: "100vh",
  top: "27%",
  left: 0,
  right: 0,
  "& .MuiFormControl-root": {
    "& .MuiFormLabel-root": {
      marginLeft: "9px",
      fontSize: ".85rem",
      "&:active": {
        color: "#F1B855",
      },
      "&.Mui-focused": {
        color: "#F1B855",
      },
      "&.Mui-error": {
        color: "#F44336",
      },
    },
  },
  "& .MuiInputBase-root": {
    "&.MuiFilledInput-root": {
      borderRadius: "9px",
      backgroundColor: "#FFF",
      border: "1px solid #D0D0D0",
      fontSize: ".9rem",
      color: "#444",
      "&::before": {
        borderBottom: "none !important",
        borderRadius: "9px",
      },
      "&::after": {
        borderBottom: "none !important",
        borderRadius: "9px",
      },
      "&:active": {
        borderColor: "#F1B855",
      },
      "&:hover": {
        borderColor: "#F1B855",
      },
      "&.Mui-focused": {
        borderColor: "#F1B855",
      },
      "&.Mui-error": {
        borderColor: "#F44336",
      },
    },
  },
  "& .MuiSelect-select": {
    borderRadius: "15px",
  },
})
export const LoginFormLayout = styled(Paper)({
  boxShadow: "3px 4px 5px -1px rgb(0 0 0 / 15%), 0px 2px 2px rgb(0 0 0 / 9%), 0px 1px 5px rgb(0 0 0 / 7%)",
  backgroundColor: "#FAFAFA",
  padding: "30px 0px 15px",
  borderRadius: "8px",
  width: "fit-content",
  margin: "0 auto",
})
export const UserAvatar = styled(Box)({
  width: "70px",
  height: "70px",
  position: "absolute",
  top: "-3.5%",
  "@media (max-height: 1130px) and (min-height: 1020px)": {
    top: "-4%"
  },
  "@media (max-height: 1019px) and (min-height: 880px)": {
    top: "-4.5%"
  },
  "@media (max-height: 879px) and (min-height: 725px)": {
    top: "-5.25%"
  },
  "@media (max-height: 724px) and (min-height: 624px)": {
    top: "-6.5%"
  },
  "@media (max-height: 623px) and (min-height: 460px)": {
    top: "-7.5%"
  },
  "@media (max-height: 459px)": {
    top: "-10.5%"
  },
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
  boxShadow: "0px 0px 2px rgba(95, 95, 95, .75), 0px 0px 2px 3px rgba(236, 240, 243, .75), 3px 3px 7px rgba(167, 170, 175, .75), -8px -8px 15px rgba(255, 255, 255, .75)",
  borderRadius: "50px",
  border: "1px solid #F9F9F9",
})
export const LoginBtn = styled(Button)({
  width: "92%",
  background: "#F1B855",
  borderRadius: "7px",
  boxShadow:
    "rgb(0 0 0 / 20%) 0px 1px 3px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
  fontSize: "15px",
  "&:hover": {
    background: "#EEAB36",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 1px 3px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
  },
})

export const LoginCheckboxIcon = styled("span")({
  borderRadius: 6.5,
  width: 22,
  height: 22,
  backgroundColor: "#FFF",
  backgroundImage:
    "linear-gradient(180deg, hsla(0,0%,100%,.8), hsla(0,0%,100%,0))",
  border: "1px solid #E9E9E9",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(238, 171, 54, .6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "rgba(238, 171, 54, .2)",
    border: "1px solid #EEAB36",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
})

export const LoginCheckIcon = styled(LoginCheckboxIcon)({
  backgroundColor: "#F1B855",
  backgroundImage:
    "linear-gradient(180deg, hsla(0,0%,100%,.1), hsla(0,0%,100%,0))",
  border: "none",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 90 90'%3E%3Cpath fill='%23fff' d='M34.4 72c-1.2 0-2.3-.4-3.2-1.3L11.3 50.8c-1.8-1.8-1.8-4.6 0-6.4 1.8-1.8 4.6-1.8 6.4 0l16.8 16.7 39.9-39.8c1.8-1.8 4.6-1.8 6.4 0 1.8 1.8 1.8 4.6 0 6.4l-43.1 43c-1 .9-2.1 1.3-3.3 1.3z' class='color000 svgShape'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#EEAB36",
    border: "none",
  },
})

export function LoginCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<LoginCheckIcon />}
      icon={<LoginCheckboxIcon />}
      inputProps={{ "aria-label": "Remember me" }}
      {...props}
    />
  )
}
