import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={'100%'}
    height={'100%'}
    fill="none"
    viewBox="0 0 97 88"
    preserveAspectRatio="xMinYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <path
        d="M96.29 5.31 91.44.68a2.434 2.434 0 0 0-.787-.503 2.523 2.523 0 0 0-1.856 0 2.438 2.438 0 0 0-.787.503l-29.1 27.789c-.225.215-.403.47-.525.751a2.224 2.224 0 0 0-.185.886v4.632c0 .614.255 1.203.71 1.637a2.485 2.485 0 0 0 1.715.679h4.85c.318 0 .633-.06.928-.176.294-.117.561-.288.786-.503l29.1-27.79c.225-.214.404-.47.526-.75a2.225 2.225 0 0 0 0-1.773c-.122-.28-.3-.536-.525-.75Z"
        fill="url(#b)"
      />
      <path
        d="M65.475 41.684h-4.85c-1.929-.002-3.778-.734-5.142-2.037-1.364-1.302-2.131-3.068-2.133-4.91v-4.632a6.632 6.632 0 0 1 .55-2.66 6.89 6.89 0 0 1 1.581-2.252l16.682-15.93H12.125c-3.215.004-6.297 1.225-8.57 3.395C1.282 14.83.003 17.772 0 20.842v41.684c.003 3.07 1.282 6.014 3.555 8.184 2.273 2.17 5.355 3.392 8.57 3.395H33.95v11.58c0 .429.125.851.362 1.216.237.366.576.662.979.855a2.53 2.53 0 0 0 1.302.235 2.493 2.493 0 0 0 1.237-.454l18.756-13.432h23.439c3.215-.003 6.296-1.224 8.57-3.395 2.273-2.17 3.552-5.114 3.555-8.184V19.087L70.619 39.65a7.257 7.257 0 0 1-2.36 1.51c-.882.349-1.828.528-2.784.525Zm-48.5-18.526h24.25c.643 0 1.26.244 1.715.678.455.435.71 1.024.71 1.638 0 .614-.255 1.203-.71 1.637a2.485 2.485 0 0 1-1.715.678h-24.25c-.643 0-1.26-.243-1.715-.678a2.264 2.264 0 0 1-.71-1.637c0-.614.255-1.203.71-1.638a2.484 2.484 0 0 1 1.715-.678Zm0 13.895h24.25c.643 0 1.26.244 1.715.678.455.434.71 1.023.71 1.638 0 .614-.255 1.203-.71 1.637a2.485 2.485 0 0 1-1.715.678h-24.25c-.643 0-1.26-.244-1.715-.678a2.263 2.263 0 0 1-.71-1.637c0-.615.255-1.204.71-1.638a2.484 2.484 0 0 1 1.715-.678ZM77.6 53.263c0 .614-.256 1.204-.71 1.638a2.485 2.485 0 0 1-1.715.678h-58.2c-.643 0-1.26-.244-1.715-.678a2.263 2.263 0 0 1-.71-1.638c0-.614.255-1.203.71-1.637a2.484 2.484 0 0 1 1.715-.679h58.2c.643 0 1.26.244 1.715.679.454.434.71 1.023.71 1.637Z"
        fill="url(#c)"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={14.991}
        y1={71.704}
        x2={109.183}
        y2={47.589}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.479} stopColor="#EEAB36" />
        <stop offset={1} stopColor="#FFCC72" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={14.991}
        y1={71.704}
        x2={109.183}
        y2={47.589}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.479} stopColor="#EEAB36" />
        <stop offset={1} stopColor="#FFCC72" />
      </linearGradient>
      <filter
        id="a"
        x={0}
        y={0}
        width={102}
        height={88}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={6} />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1504_11208" />
      </filter>
    </defs>
  </svg>
)

export default SvgComponent