import { styled } from "@mui/material/styles"
import Button from "@mui/material/Button"

export const ChatConversation = styled(Button)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-evenly",
  height: "145px",
  width: "92%",
  borderRadius: "19px",
  backgroundColor: "#FFFFFF",
  marginTop: "18.5px",
  padding: "9px 11px",
  textTransform: "none",
  boxShadow: "0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 1.5px 1.5px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%)",
  "&:last-child": {
    marginBottom: "18.5px",
  },
})