import React, {forwardRef, useState, useEffect} from "react"
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StatusView({ state, message, status  }) {

  const StatusComponent = ()=>{
    if(status == "success"){
      return( <><Typography variant="h4" component="div" gutterBottom style={{ fontWeight: "400", color: "#2e7d32" }}>{message}</Typography>
      <CheckCircleOutlineIcon  sx={{ fontSize: 120, color: '#2e7d32' }} /></>);
    }
    else if (status == "error"){
      return( <><Typography variant="h4" component="div" gutterBottom style={{ fontWeight: "400", color: 'indianred' }}>{message}</Typography>
      <ErrorOutlineIcon  sx={{ fontSize: 120, color: 'indianred' }} /></>);
    }
    else{
      return(
        <>
        <Typography variant="h4" component="div" gutterBottom style={{ fontWeight: "400", color: 'gray' }}>{message}</Typography>
        <ErrorOutlineIcon color="disabled" sx={{ fontSize: 120, color: 'gray' }} />
        </>
      );
    }
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={state}
        TransitionComponent={Transition}
      >
        <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <StatusComponent/>
        </div>
      </Dialog>
    </div>
  );
}
