import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  reviewOptionsBtn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
    transform: "translate(-8px, -18px)",
    color: "rgba(68, 68, 68, 0.54)",
  },
  reviewOptionsContainer:{
    "& .MuiPaper-root": {
      "&.MuiDialog-paper": {
        minWidth: "320px",
        width: "96%",
        maxWidth: "420px",
        borderRadius: "25px"
      }
    }
  },
  closeOptionsBtn: {
    color: "rgba(68, 68, 68, 0.54)",
    marginRight: "5px"
  },
  reviewOptionsHeader: {
    display: 'flex',
    alignItems: "center",
    textAlign: 'center',
    justifyContent: 'space-between',
    marginTop: "13px"
  },
  reviewOptionsTitle:{
    marginLeft: "15px",
    color: "rgba(68, 68, 68, 0.87)",
    fontSize: "18px",
    lineHeight: "150%",
    letterSpacing: "0.15px"
  },
  reviewOptionsList: {
    padding: "0px 11px 13px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  optionBtn: {
    textDecoration: "none",
    textTransform: "none",
    color: "rgba(68, 68, 68, 0.87)",
    paddingRight: "0px",
    paddingLeft: "0px",
    fontSize: "15px",
    "@media (max-width: 400px)": {
      fontSize: "12.25px",
    },
  },
  optionIcon: {
    width: "51px",
    display: "flex",
    justifyContent: "flex-start"
  }
}));