import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  selectRoot: {
    minWidth: "240px",
    width: "80%",
    maxWidth: "325px",
    zIndex: '1',
    margin: "0 auto",
    "&.MuiInputBase-root": {
      "&.MuiOutlinedInput-root": {
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid rgb(238, 171, 54)"
          }
        },
        "&.Mui-selected": {
          border: "1px solid rgba(238, 171, 54, 0.175)",
          "&.Mui-focusVisible": {
            border: "1px solid rgba(238, 171, 54, 0.175)",
          }
        },
        "& .MuiSelect-icon": {
          color: "rgba(68, 68, 68, 0.54)",
        },
      },
      "& .MuiInputBase-input": {
        "&.MuiOutlinedInput-input": {
          padding: "10.5px 11px",
          display: "inline-flex",
          alignItems: "center",
          color: "#44444499",
          background: "#FFFFFF",
        },
      },
    },
  },
  menuItem: {
    paddingLeft: "10px",
    "&:hover": {
      background: "rgba(238, 171, 54, 0.25)",
    },
    "&.Mui-selected": {
      background: "rgba(238, 171, 54, 0.25)",
      "&:hover": {
        background: "rgba(238, 171, 54, 0.4)",
      },
      "&.Mui-focusVisible": {
        background: "rgba(238, 171, 54, 0.25)",
      },
    },
  },
  reviewsContainer: {
    paddingBottom: "58px",
    minWidth: "100%",
    position: "absolute",
    top: "62px",
  },
  reviewsSelectViewOptionsIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "50px",
    height: "33px",
    marginLeft: "3px"
  },
}))
