import { emphasize, styled } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"

export const CorrectHeaderDisplay = (pathname) => {
  let height
  if (!pathname) height = "83px"
  if (pathname) height = "53px"
  return {
    height: height,
    minHeight: height,
    maxHeight: height,
  }
}

export const ViewAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "none",
  zIndex: "1200",
  borderBottomLeftRadius: "18px",
  borderBottomRightRadius: "18px",
}))

export const ViewContainer = styled(Toolbar)(({ theme }) => ({
  boxShadow:
    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  backgroundColor: "#FFFFFF",
  color: "#343434",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderBottomLeftRadius: "18px",
  borderBottomRightRadius: "18px",
}))

export const View = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "none",
  fontSize: "1.2rem",
  fontVariant: "all-small-caps",
  letterSpacing: "0.035em",
}))

export const BackBtn = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  left: 0,
  top: '5px',
  marginLeft: "12px",
  "&:hover, &:focus": {
    backgroundColor: emphasize("#828080", 0.8),
  },
  "&:active": {
    boxShadow: theme.shadows[1],
    backgroundColor: emphasize("#828080", 0.5),
  },
}))
