import styled from "@mui/material/styles/styled"
import Button from "@mui/material/Button"
import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  ratingDisplay: {
    position: "fixed",
    height: "100vh",
    width: "325px",
    top: "27%",
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto"
  },
  videoFormStyles: {
    padding: "0px",
    maxWidth: "850px",
    position: "absolute",
    top: "8%",
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        marginLeft: "9px",
        fontSize: ".85rem",
        "&:active": {
          color: "#3679EE",
        },
        "&.Mui-focused": {
          color: "#3679EE",
        },
      },
    },
    "& .MuiInputBase-root": {
      "&.MuiFilledInput-root": {
        borderRadius: "8px",
        backgroundColor: "rgba(196, 196, 196, 0.15)",
        border: "2px solid #e9e9e9",
        fontSize: ".9rem",
        color: "#444444",
        "&::before": {
          borderBottom: "none",
          borderRadius: "15px",
        },
        "&::after": {
          borderBottom: "none",
          borderRadius: "8px",
        },
        "&:active": {
          borderColor: "#3679EE",
        },
        "&.Mui-focused": {
          borderColor: "#3679EE",
        },
      },
    },
    "& .MuiSelect-select": {
      borderRadius: "8px",
    },
  },
  videoVerificationBtn: {
    minWidth: "60px",
    width: "60px",
    height: "30px",
    color: "#8F8F8F",
    backgroundColor: "rgba(196, 196, 196, 0.15)",
    border: "none",
    outline: "2.5px solid #F1F1F1",
    borderRadius: "5px",
    fontSize: ".9rem",
    borderTopLeftRadius: "5px !important",
    borderTopRightRadius: "5px !important",
    borderBottomLeftRadius: "5px !important",
    borderBottomRightRadius: "5px !important",
    "&:hover": {
      backgroundColor: "rgba(196, 196, 196, 0.35)"
    },
    "&:nth-child(1)": {
      marginRight: "20px",
    },
    "&:nth-child(2)": {
      marginRight: "20px",
    },
    "&.Mui-selected": {
      background: "#3679EE",
      color: "#FFFFFF",
      fontWeight: "500",
      border: "none",
      outline: "2.5px solid #F1F1F1",
      "&:hover": {
        background: "#3347BC"
      }
    },
  },
}))

export const RecordButton = styled(Button)({
  width: "90px",
  height: "90px",
  marginTop: "25px!important",
  position: "relative",
  background:
    "radial-gradient(128.99% 128.99% at 52.18% 50.72%, #FB0000 0%, #FFB5B5 100%)",
  borderRadius: "75px",
  outline: "25px solid #FFFFFF",
  fontWeight: "bold",
  fontSize: "25px",
  boxSizing: "border-box",
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
  },
  "&:before": {
    content: "''",
    display: "block",
    position: "absolute",
    border: "1px solid #F1F1F1",
    top: "-1px",
    left: "-1px",
    right: "-1px",
    bottom: "-1px",
    pointerEvents: "none",
    borderRadius: "75px",
    "@keyframes pulse-red": {
      "0%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(255, 82, 82, 0.7)",
      },
      "70%": {
        transform: "scale(1)",
        boxShadow: "0 0 0 10px rgba(255, 82, 82, 0)",
      },
      "100%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(255, 82, 82, 0)",
      },
    },
    animation: "pulse-red 1800ms infinite",
  },
  "&:after": {
    content: "''",
    display: "block",
    position: "absolute",
    border: "1px solid #F1F1F1",
    top: "-11px",
    left: "-11px",
    right: "-11px",
    bottom: "-11px",
    pointerEvents: "none",
    borderRadius: "75px",
  },
})
