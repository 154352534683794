import React from "react";

export default function ReviewInviteBtn() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25">
        <defs>
          <linearGradient id="reviewInviteBtn1" x1="0" x2="0" y1="0" y2="1">
            <stop offset="100%" stopColor="#FFF" />
            <stop offset="100%" stopColor="#FFF" />
          </linearGradient>
        </defs>
        <g fill="url(#reviewInviteBtn1)">
          <path d="M4.55 16h-4a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1zm.5-3H.51a.5.5 0 1 0 0 1h4.54a.5.5 0 1 0 0-1zm.51-3H.51a.5.5 0 1 0 0 1h5.05a.5.5 0 1 0 0-1zm3.88-1.93c2 2 4.29 4.17 5 4.8a.52.52 0 0 0 .35.13.53.53 0 0 0 .18 0 1.28 1.28 0 0 0 .3-.15c.89-.6 3.79-2.5 6.49-4.27l1.89-1.24a.5.5 0 0 0 .08-.77A1.88 1.88 0 0 0 22.38 6H9.82a2 2 0 0 0-1.25.43.5.5 0 0 0 0 .75z" />
          <path d="M23.78 8.78a.51.51 0 0 0-.56 0l-1.13.74c-2.64 1.73-5.41 3.54-6.28 4.13a1.67 1.67 0 0 1-2.09 0c-.69-.64-3-2.86-5-4.84l-.28-.31a.51.51 0 0 0-.5-.12.5.5 0 0 0-.35.38l-1.49 7A1.86 1.86 0 0 0 7.94 18H20.5a2 2 0 0 0 2-1.59L24 9.3a.5.5 0 0 0-.22-.52zM.51 8h5.55a.5.5 0 1 0 0-1H.51a.5.5 0 1 0 0 1z" />
        </g>
      </svg>
    </>
  );
}
