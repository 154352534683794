import Typography from "@mui/material/Typography";

export default function LegalJargon(props){

    const name = props.companyName === "" || !props.companyName ? "the company in question" : props.companyName;
    const hipaa_content = props.isHipaa ? `By providing this review, I consent to ${name} using my comments on their website and other promotional materials. I understand that my review may be de-identified to protect my privacy, and I have the right to withdraw my consent at any time by contacting ${name}` : "";
    const content = `By submitting your review to SoTellUs, you authorize the unrestricted use of your review by SoTellUs and by the person/entity that requested the review. You hereby grant the unrestricted, irrevocable, worldwide, royalty-free right and license to copyright and use, re-use, publish, and republish photos, videos, and/or audio recordings of me or in which I may be included, intact or in part, in composite or distorted in character or form, without restriction as to changes, for unlimited purposes (both commercial and non-commercial), and through any and all media and methods of distribution, now known or hereafter developed. All rights of privacy and publicity are hereby waived, including the right to use my name with my photos and/or videos. ${hipaa_content}`

    return(
        <Typography
        variant="p"
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "10px",
          justifyContent: "center",
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "10px",
          color: "#999",
        }}
      >
        {content}
      </Typography>
    )   
}