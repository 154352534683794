import React from "react"

export default function VolumeIconSVG(props) {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="40" fill="none">
        <path fill="url(#volume)" d="M24.5455 39.2719c-.5572.0003-1.0978-.189-1.5329-.5369l-11.6004-9.2812H2.45455c-.32236 0-.64158-.0634-.93942-.1867a2.4527946 2.4527946 0 0 1-.796397-.5321c-.227942-.2279-.40874-.4985-.532063-.7964-.1233226-.2978-.1867538-.617-.18667-.9394V12.2719c-.0000838-.3223.0633474-.6415.18667-.9394.123323-.2978.304121-.5684.532063-.7964.227942-.2279.498567-.4087.796397-.532.29784-.12336.61706-.18679.93942-.18671h8.95765L23.0126.536143c.3611-.288604.7964-.4693669 1.2558-.5214838.4593-.052117.924.0265307 1.3407.2268898.4166.20036.7682.514288 1.0142.905651.2461.39137.3767.84426.3767 1.30656V36.8174c0 .3223-.0634.6415-.1868.9393-.1233.2978-.3041.5684-.5321.7964-.2279.2279-.4985.4087-.7963.532-.2978.1234-.617.1869-.9393.1868Z"/>
        <defs>
          <linearGradient id="volume" x1="13.5" x2="13.5" y1="-0.000991821" y2="39.2719" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff"/>
            <stop offset="0.53125" stopColor="#F5F5F5"/>
            <stop offset="1" stopColor="#F9FCF5"/>
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}