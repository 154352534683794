import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
  StatsLayout: {
    paddingBottom: "58px",
    minWidth: "100%",
    position: "absolute",
    top: "62px",
  },
  ViewToggleGroupContainer: {
    display: "flex",
    flexFlow: "wrap",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "650px",
    margin: "0 auto",
  },
  ViewToggleBtn: {
    flex: "0 0 25.555555%",
    backgroundColor: "#FAFAFA",
    color: "#444444",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    height: "40px",
    fontSize: "12px",
    border: "none",
    padding: "4px 8px",
    borderTopLeftRadius: "25px !important",
    borderTopRightRadius: "25px !important",
    borderBottomLeftRadius: "25px !important",
    borderBottomRightRadius: "25px !important",
    "&:nth-of-type(1)": {
      marginRight: "20px",
      marginBottom: "14px",
    },
    "&:nth-of-type(2)": {
      marginRight: "20px",
      marginBottom: "14px",
    },
    "&:nth-of-type(3)": {
      marginBottom: "14px",
    },
    "&:nth-of-type(4)": {
      marginRight: "20px",
    },
    "&:hover": {
      background: "#F6F6F6",
      color: "#808080",
    },
    "&:focus": {
      background: "#5927E5",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#5927E5",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      background: "#5927E5",
      color: "#FFFFFF",
      "&:hover": {
        background: "#371ad6",
      },
    },
  },
  CenteredStatsContainer: {
    marginTop: "30px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "200px",
    height: "200px",
    margin: "0 auto",
  },
  /*
  BottomStatsContainer: {
    paddingTop: "38px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },*/
  RatingsListContainer: {
    paddingTop: "38px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  StatsTimespanBtn: {
    backgroundColor: "#FAFAFA",
    color: "#444444",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    height: "38px",
    fontSize: "14px",
    letterSpacing: ".25px",
    border: "none",
    padding: "4px 8px",
    borderTopLeftRadius: "9px !important",
    borderTopRightRadius: "9px !important",
    borderBottomLeftRadius: "9px !important",
    borderBottomRightRadius: "9px !important",
    "&:hover": {
      background: "#F6F6F6",
      color: "#808080",
    },
    "&:focus": {
      background: "#2196F3",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#2196F3",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      background: "#2196F3",
      color: "#FFFFFF",
      "&:hover": {
        background: "#1a75d2",
      },
    },
  },
  BottomStatsContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    width: "100%",
    height: "225px",
    left: "0px",
    background: "#FFFFFF",
    boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px 15px 0px 0px",
  },
  RatingsDataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "55px",
  },
  STURatingsIcon: {
    backgroundColor: "#323232",
    color: "white",
    marginBottom: "10",
    borderRadius: "10",
    "&:hover": {
      background: "#323232",
      color: "#808080",
    },
  },
  StatCardTextShadow: {
    textShadow: "3px 3px 5px rgba(0, 0, 0, 0.24)",
  },

  Slider: {
    width: "100%",
    height: "90%",
    textAlign: "center",
    overflow: "hidden",
  },
  Slides: {
    display: "flex",
    height: "100%",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  SlideItem: {
    "scroll-snap-align": "start",
    "flex-shrink": "0",
    width: "300px",
    height: "auto",
    "margin-right": "50px",
    "border-radius": " 10px",
    background: "black",
    "transform-origin": "center center",
    transform: "scale(1)",
    transition: "transform 0.5s",
    position: "relative",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "font-size": "100px",
  },
}))
