import React, {useState, useEffect, forwardRef, useContext, useRef, useCallback} from "react";
import "@fontsource/roboto";
import StarRating from "@Rating";
import ExampleLogo from "@ExampleCompanyLogo";
import { useStyles, RecordButton } from "@VideoReviewStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import parsePhoneNumber from 'libphonenumber-js'
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import VideoRecorder from "react-video-recorder";
import Button from "@mui/material/Button";

import Timer from "react-video-recorder/lib/defaults/timer";
import Countdown from "react-video-recorder/lib/defaults/countdown";
import styled from "styled-components";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import StatusComponent from "../../components/StatusView.component";
import { purple, green, red, blue } from "@mui/material/colors";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import VolumeIconSVG from "@VolumeIcon";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import { AppContext } from "../../utils/AppContext";
import { db } from "../../utils/db";
import { MutatingDots } from  'react-loader-spinner'
import LegalJargon from "../../components/LegalJargon";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoReview(props) {
  const app = useContext(AppContext);
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") > -1
      ? false
      : true;
  let api_token = localStorage.getItem("api_token");
  const classes = useStyles();

  const [hasSavedPR, setHasSavedPR] = useState(
    parseInt(app.reviewID) === -1 ? false : true
  );
  const [PRID, setPRID] = useState(
    parseInt(app.reviewID) === -1
      ? new Date().getTime()
      : parseInt(app.reviewID)
  );
  const [attemptingUpload, setAttemptingUpload] = useState(false);
  const [starRating, setStarRating] = useState(0);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [verification, setVerificationMethod] = useState("text");
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState(null)
  const [email, setEmail] = useState("");
  const [statecode, setStateCode] = useState(
    localStorage.getItem("DefaultCountry")
      ? localStorage.getItem("DefaultCountry")
      : "us"
  );

  const [videoFileDialogSelected, setVideoFileDialogSelected] = useState(false)
  const [videoDialog, setVideoDialog] = useState(false);
  let video = null;
  const [videoFile, setVideoFile] = useState(null);
  const [videoURL, setVideoURL] = useState("");
  const [videostate, setVideoState] = useState(false);
  const [formDisplay, showFormDisplay] = useState(false);
  const [pendingReview, setPendingReview] = useState({
    type: "video",
    starweight: 2,
  });
  const [snackbarMsg, setSnackbarMsg] = useState("Complete All Fields");
  const [snackbarState, setSnackBarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });

  const inputFile = useRef(null)
  const [recorderRefresh, setRecorderRefresh] = useState();
  const [statusState, setStatusState] = useState(false);
  const { vertical, horizontal, open } = snackbarState;
  const [openShouldUploadPrompt, setOpenShouldUploadPrompt] = useState(false);

  useEffect(() => {
    setVideoState(false);
    //init PRS if needed
    if (!localStorage.getItem("PendingReviews"))
      localStorage.setItem("PendingReviews", JSON.stringify([{}]));
    if (parseInt(app.reviewID) === -1) {
      setPendingReview((prev) => ({
        ...prev,
        token: api_token,
        PRID: PRID,
      }));
    }
  }, []);

  useEffect(() => {
    //checks if PR before implementation
    implementSavedPendingReview();
  }, [app.reviewID]);

  //*! PendingReview Updated - only starts saving after input of first name
  useEffect(() => {
    if (pendingReview.PRID !== undefined && pendingReview.firstname) {
      if (!hasSavedPR) {
        app.setPRVideoCount(app.PRVideoCount + 1);
        setHasSavedPR(true);
      }
      savePendingReview();
    }
  }, [pendingReview]);


  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      videoURL: videoURL,
    }));
  }, [videoURL]);

  useEffect(() => {
    if (starRating) {
      if (starRating >= 1) {
        showFormDisplay(true);
      }
    }
    setPendingReview((prev) => ({
      ...prev,
      stars: starRating,
    }));
  }, [starRating]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      firstname: firstname,
    }));
  }, [firstname]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      lastname: lastname,
    }));
  }, [lastname]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      mobile: mobile,
    }));
  }, [mobile]);
  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      email: email,
    }));
  }, [email]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      verificationmethod: verification,
    }));
  }, [verification]);

  //*!HELPER FUNCTIONS

  const toBoolean = (str) => {
    if (str === "true") return true;
    return false;
  };
  const refresh = () => {
    // re-renders the component
    setRecorderRefresh({});
  };

  const savePendingReview = () => {
    //get current PRS
    let PRS = JSON.parse(localStorage.getItem("PendingReviews"));
    if (PRS.length === 1) {
      PRS = PRS[0];
    }
    PRS[pendingReview.PRID] = pendingReview;
    //SAVE updated PRS
    localStorage.setItem("PendingReviews", JSON.stringify(PRS));
  };
  const removePendingReview = (PRID) => {
    let PRS = JSON.parse(localStorage.getItem("PendingReviews"));
    if (PRS.length === 1) {
      PRS = PRS[0];
    }
    delete PRS[PRID];

    localStorage.setItem("PendingReviews", JSON.stringify(PRS));
  };
  const implementSavedPendingReview = () => {
    if (parseInt(app.reviewID) !== -1) {
      let prs = JSON.parse(localStorage.getItem("PendingReviews"));
      if (prs.length === 1) prs = prs[0];
      Object.keys(prs).forEach((i) => {
        let pr = prs[i];
        if (pr.PRID.toString() === app.reviewID) {
          showFormDisplay(true);
          inputAllFields(pr);
          setPendingReview(pr);
          /*
          if (pr.video) {
            video = getVideo(pr);
            setVideoURL(`${window.URL.createObjectURL(video)}#t=1`);
          }
          */
          setVideoState(pr.video);
          return;
        }
      });
    }
  };

  const inputAllFields = async (pr) => {
    setStarRating(pr.stars);
    setFirstName(pr.firstname);
    setLastName(pr.lastname);
    setVerificationMethod(pr.verificationmethod);
    setMobile(pr.mobile);
    setEmail(pr.email);
  };
  const handleShouldUploadOpen = () => {
    setOpenShouldUploadPrompt(true);
  };
  const handleShouldUploadClose = () => {
    setOpenShouldUploadPrompt(false);
  };
  const handleClose = () => {
    setSnackBarState({ ...snackbarState, open: false });
  };
  const handleRatingChange = (starRatingData) => {
    setStarRating(starRatingData);
    showFormDisplay(true);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleVerificationChange = (event, response) => {
    if (response !== null) {
      setVerificationMethod(response);
    }
  };
  const handleMobileChange = (phone, country) => {
    setMobile(`+${phone}`);
    setStateCode(country.countryCode);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleVideoDialogOpen = () => {
    setVideoDialog(true);
  };

  const handleVideoDialogClose = () => {
    setVideoDialog(false);
    if (video !== null && video !== undefined) {
      console.log("Saving video...");
      //saveVideo();
    }
  };

  const handleVideoRecordButton = () => {
    if (isMEBlank()) {
      setSnackBarState({
        open: true,
        vertical: "bottom",
        horizontal: "center",
        severity: "success",
      });
      return false;
    }
    if (!isFormComplete()) return;
    handleVideoDialogOpen();
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setVerificationMethod("text");
    setMobile("");
    setEmail("");
  };
  //ME = MobileEmail
  const isMEBlank = () => {
    if (pendingReview.mobile === "" && pendingReview.email === "") return true;
    return false;
  };

  const isFormComplete = () => {
    if (firstname === "" || lastname === "") {
      setSnackBarState({
        open: true,
        vertical: "bottom",
        horizontal: "center",
        severity: "success",
      });
      return false;
    }
    return true;
  };

  //*!FIRST API POST: initialize to recieve video token & review id
  const handleInitVideoSubmit = async () => {
    let formData = new FormData();
    formData.append("type", "video");
    formData.append("token", pendingReview.token);
    formData.append("stars", pendingReview.stars);
    formData.append("starweight", pendingReview.starweight);
    formData.append("firstname", pendingReview.firstname);
    formData.append("lastname", pendingReview.lastname);
    formData.append("mobile", pendingReview.mobile);
    formData.append("email", pendingReview.email);
    try {
      let response = await fetch(process.env.REACT_APP_API_URL+"reviews?api_token=uhE1DtkadYuxRkNn5UQb71UimUDnXoIZbi6TU0I5aEoSbhbqKy77ZvBIObn4qqA6GP4fe76XstCIdNn6JNEws1t3JW8nh2OSLpbziDwi56eGOqjzA0nB-A-8Zgq_AxQN", {
        method: "POST",
        headers: {
        },
        body: formData,
      });
      let result = await response.json();

      if (result.status === "success") {
        //grab reviewid & upload token
        setPendingReview((prev) => ({
          ...prev,
          id: result.data.id,
        }));
        setPendingReview((prev) => ({
          ...prev,
          uploadToken: result.data.uploadToken,
        }));
        return result;
      } else {
        //There was an unsuccessful api call to submit video information
        setError(result.error)
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  //*!SECOND API POST: Submit Video
  const handleVideoUpload = async (reviewID, uploadToken, v) => {
    let formData = new FormData();
    formData.append("id", reviewID);
    formData.append("uploadToken", uploadToken);
    formData.append("type", "video");
    formData.append("token", pendingReview.token);
    formData.append("stars", pendingReview.stars);
    formData.append("starweight", pendingReview.starweight);
    formData.append("firstname", pendingReview.firstname);
    formData.append("lastname", pendingReview.firstname);
    formData.append("mobile", pendingReview.mobile);
    formData.append("email", pendingReview.email);
    formData.append("video", v);
    try {
      let response = await fetch(
          process.env.REACT_APP_API_URL+`reviews/${reviewID}/upload?uploadToken=${uploadToken}`,
        {
          method: "POST",
          body: formData,
        }
      );
      let result = await response.json();
      if (result.status == "success") {
        setStatusState(true);
        setTimeout(() => {
          handleDeleteReview();
        }, 3000);
      } else {
        //unsuccessful submission
        setError(result.error)
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSwitchBtnClick = (e) => {
    e.preventDefault();
    let videoscreen = document.getElementsByClassName(
      "video-recorder__Video-sc-7k20rv-2"
    )[0];
    if (videoscreen.style.transform === "translate(-50%, -50%) scaleX(1)")
      videoscreen.style.transform = "translate(-50%, -50%) scaleX(-1)";
    else videoscreen.style.transform = "translate(-50%, -50%) scaleX(1)";

    let switchbtn = document.getElementsByClassName(
      "switch-camera-view__SVGWrapper-sc-13l3hbu-0"
    )[0];
    switchbtn.removeEventListener("click", handleSwitchBtnClick, true);
  };

  function isPhoneValid(){
    const phoneNumber = parsePhoneNumber(mobile, 'US')
    if(phoneNumber){
      return phoneNumber.isValid()
    }else{
      return false
    }
  }

  function validateEmail() {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleVideoSubmit = async () => {
    setAttemptingUpload(true);
    let isSuccess = await handleInitVideoSubmit();
    if (isSuccess) {
      await handleVideoUpload(
        isSuccess.data.id,
        isSuccess.data.uploadToken,
        videoFile
      );
      setAttemptingUpload(false)
    }else{
      setAttemptingUpload(false)
      setStatusState(true)
      setTimeout(()=>{
        setStatusState(false)
      }, 1500)
    }
  };

  const handleVideoClick = (event, mid) => {
    let m = document.getElementById(mid);
    let v = event.target;
    if (v.paused) {
      v.play();
      v.mute = false;
    } else {
      v.pause();
      v.mute = true;
      m.style.display = "flex";
    }
  };

  const handleDeleteReview = () => {
    setStatusState(false);
    clearFields();
    removePendingReview(pendingReview.PRID);
    //deleteVideo(pendingReview.PRID);
    app.setPRVideoCount(app.PRVideoCount - 1);
    app.setReviewID(-1);
    handleVideoDialogClose();
    document.getElementById("back-button").click();
  };

  const clickUnmute = (e, vid) => {
    e.preventDefault();
    let v = document.getElementById(vid);
    if (v.paused) {
      v.play();
      v.mute = false;
      e.target.style.display = "none";
    } else {
      v.pause();
      v.mute = true;
      e.target.style.display = "flex";
    }
  };
  const saveVideo = async () => {
    let id = pendingReview.PRID;
    let item = { prid: id, file: video };
    let result = await db.items.add(item);
  };

  const getVideo = async (pr) => {
    let result = await db.items.get(pr.PRID);
    return result.file;
  };

  const deleteVideo = async () => {
    let result = await db.items.delete(pendingReview.PRID);
    return result;
  };

  const ActionsWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  }

  const ErrorView = () => (
    <div>
      Sorry! This Browser Failed To Record!
      <br />
      <br />
      Safari is Incompatiable with video Recording. Please Try Google Chrome or
      Mozilla Firefox.
    </div>
  );

  const ShouldUploadPrompt = () => {
    return (
      <Dialog
        open={openShouldUploadPrompt}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Submit Video Over WiFi?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please confirm to make sure you are connected to WiFi
            before submitting. If you choose to use Mobile Data for submitting video,
            charges may be applied with your carrier.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShouldUploadClose}>No, don't submit</Button>
          <Button
            onClick={() => {
              handleShouldUploadClose();
              handleVideoSubmit();
            }}
          >
            Yes, complete submission
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const Recorder = useCallback(() => {
    return (
      <VideoRecorder
        isFlipped={true}
        showReplayControls={true}
        replayVideoAutoplayAndLoopOff={false}
        countdownTime={3000}
        mimeType={isSafari?"video/mp4":"video/webm;codecs=vp9,opus"}
        onRecordingComplete={(videoBlob) => {
          if (isSafari) {
            handleVideoDialogClose();
            setVideoState(true);
            video = blobToFile(
              videoBlob,
              `${pendingReview.PRID.toString()}.mp4`
            );
            setVideoFile(video);
            setVideoURL(window.URL.createObjectURL(video));
          } else {
            video = blobToFile(
              videoBlob,
              `${pendingReview.PRID.toString()}.webm`
            );
            setVideoFile(video);
          }
        }}
        renderErrorView={() => {
          inputFile.current.click();
          setVideoState(false)
          return (<></>)
        }}
        renderActions={(r) => {
          const renderContent = (r) => {
            const shouldUseVideoInput =
              !r.isInlineRecordingSupported && r.isVideoInputSupported;

            if (
              (!r.isInlineRecordingSupported && !r.isVideoInputSupported) ||
              r.thereWasAnError ||
              r.isConnecting ||
              r.isRunningCountdown
            ) {
              return null;
            }

            if (r.isReplayingVideo) {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "-75vh",
                      right: "0",
                      margin: "25px",
                      color: blue[500],
                      backgroundColor: "white",
                      borderRadius: "25px",
                      border: "0",
                      fontSize: "20px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      video = null;
                      r.onTurnOffCamera();
                      handleVideoDialogClose();
                    }}
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    style={{
                      borderRadius: "50%",
                      height: "75px",
                      width: "75px",
                      border: "none",
                      backgroundColor: red[500],
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                    type="button"
                    onClick={r.onStopReplaying}
                    data-qa="start-replaying"
                  >
                    {/*<RedoRoundedIcon sx={{ color: red[100], fontSize: 50 }} />*/}
                    <span style={{ color: "white" }}>Retake</span>
                  </button>
                  <button
                    style={{
                      borderRadius: "50%",
                      height: "75px",
                      width: "75px",
                      border: "none",
                      backgroundColor: green[500],
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      r.onTurnOffCamera();
                      handleVideoDialogClose();
                      setVideoState(true);
                      setVideoURL(`${window.URL.createObjectURL(video)}#t=1`);
                      setVideoFile(video);
                      setPendingReview((prev) => ({
                        ...prev,
                        video: true,
                      }));
                    }}
                  >
                    <span style={{ color: "white" }}>Use Video</span>
                  </button>
                </div>
              );
            }

            if (r.isRecording) {
              return (
                <button
                  style={{
                    borderRadius: "50%",
                    height: "75px",
                    width: "75px",
                    border: "none",
                    backgroundColor: red[500],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  type="button"
                  onClick={r.onStopRecording}
                  data-qa="stop-recording"
                >
                  <StopRoundedIcon sx={{ color: red[100], fontSize: 50 }} />
                </button>
              );
            }

            if (r.isCameraOn && r.streamIsReady) {
              let switchbtn = document.getElementsByClassName(
                "switch-camera-view__SVGWrapper-sc-13l3hbu-0"
              )[0];
              if(switchbtn) {
                switchbtn.addEventListener("click", handleSwitchBtnClick);
              }

                return (
                    <button
                        style={{
                          borderRadius: "50%",
                          height: "75px",
                          width: "75px",
                          border: "none",
                          backgroundColor: red[500],
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        type="button"
                        onClick={r.onStartRecording}
                        data-qa="start-recording"
                    >
                      <CircleRoundedIcon sx={{color: red[400], fontSize: 70}}/>
                    </button>
                );

            }

            if (r.useVideoInput) {
              return (
                <button
                  type="button"
                  onClick={r.onOpenVideoInput}
                  data-qa="open-input"
                >
                  Video Options
                </button>
              );
            }

            return shouldUseVideoInput ? (
              <button
                type="button"
                onClick={r.onOpenVideoInput}
                data-qa="open-input"
              >
                record a video
              </button>
            ) : (
              <button
                type="button"
                onClick={r.onTurnOnCamera}
                data-qa="turn-on-camera"
              >
                turn on my camera
              </button>
            );
          };

          return (
            <div>
              {r.isRecording && <Timer timeLimit={r.timeLimit} />}
              {r.isRunningCountdown && (
                <Countdown countdownTime={r.countdownTime} />
              )}
              <ActionsWrapper>{renderContent(r)}</ActionsWrapper>
            </div>
          );
        }}
      />
    );
  },[pendingReview]);

  function LoadingContainer(){
    return(
      <div style={{display:'flex', justifyContent: 'center', alignItems:'center', height:'100vh'}}>
        <div style={{backgroundColor: 'rgba(255,255,255,.5)',filter:'blur(50px)', position: 'absolute', zIndex: 9, height:'100vh', width: '100vw', }}></div>
        <div style={{fontSize: 22, zIndex:10, position: 'relative', backgroundColor: 'white', padding: 10, borderRadius: '10px', border: '1px solid #eee', width: 250,textAlign:'center', margin: '0 auto' }}> <MutatingDots height="100" width="100" color="#f0b144" secondaryColor= '#f0b144' radius='12.5' ariaLabel="mutating-dots-loading" wrapperStyle={{justifyContent: 'center'}}wrapperClass=""visible={true}/>Submitting...</div>
      </div>
    )
  }

  const LogoURL = localStorage.getItem("logo_url") ?? null

  return (
    <>
      {attemptingUpload && <LoadingContainer/>}
      <CssBaseline />
      <Container fixed>
        <input type={'file'} id={'file'} ref={inputFile} multiple={false} accept={'video/mp4,video/x-m4v,video/*'} onChange={(e)=>{
          setVideoState(false)
          setVideoDialog(false)
          setVideoFileDialogSelected(true)
          setVideoFile(e.currentTarget.files[0])
        }} style={{display: 'none'}}/>
        <Box
          component="form"
          autoComplete="off"
          className={
            formDisplay ? classes.videoFormStyles : classes.ratingDisplay
          }
        >
          <Grid
            container
            direction="column"
            sx={{ padding: "11px 0px", textAlign: "center" }}
          >
            {!formDisplay && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "16px",
                }}
              >

              </Box>
            )}
            <div style={{margin: '0 auto'}}>
              {LogoURL?
                  <img src={LogoURL} width={300}/>
                  : <ExampleLogo/>}
            </div>
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12px",
                justifyContent: "center",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "22px",
                lineHeight: "133.4%",
                color: "#444444",
              }}
            >
              How was your experience?
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 6px 16px",
              }}
            >
              <StarRating
                value={starRating}
                handleRatingChange={handleRatingChange}
              />
            </Box>
            {formDisplay && (
              <>
                <Grid item xs={12} mb={1}>
                  <TextField
                    label="First Name"
                    sx={{ width: "90%" }}
                    variant="filled"
                    value={firstname}
                    onChange={handleFirstNameChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">&nbsp;</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Last Name"
                    sx={{ width: "90%" }}
                    variant="filled"
                    value={lastname}
                    onChange={handleLastNameChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">&nbsp;</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  spacing={0}
                  sx={{
                    width: "auto",
                    alignItems: "flex-start",
                    margin: "0 auto",
                  }}
                >
                  <Grid item xs={12} sx={{ marginBottom: "4px" }}>
                    <Typography
                      component="div"
                      sx={{ fontSize: "12px", color: "#8F8F8F" }}
                    >
                      Verification Type
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <ToggleButtonGroup
                      sx={{ justifyContent: "flex-start" }}
                      value={verification}
                      exclusive
                      onChange={handleVerificationChange}
                    >
                      <ToggleButton
                        className={classes.videoVerificationBtn}
                        value="text"
                      >
                        Text
                      </ToggleButton>
                      <ToggleButton
                        className={classes.videoVerificationBtn}
                        value="email"
                      >
                        Email
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                {verification === "text" || verification === "call" ? (
                  <Grid container item sx={{ width: "auto", margin: "0 auto" }}>
                    <Grid item xs={12} mb={1}>
                      <PhoneInput
                        country={statecode}
                        placeholder=""
                        autoFormat={localStorage.getItem("DisablePhoneAutoFormat")
                        ? !toBoolean(localStorage.getItem("DisablePhoneAutoFormat"))
                        : true}
                        value={mobile}
                        style={{ marginBottom: "10px" }}
                        onChange={(phone, country) =>
                          handleMobileChange(phone, country)
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} mb={1}>
                    <TextField
                      label="Email"
                      variant="filled"
                      sx={{ width: "90%" }}
                      onChange={handleEmailChange}
                      value={email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            &nbsp;
                          </InputAdornment>
                        ),
                      }}is
                    />
                  </Grid>
                )}
                <Grid item xs={12} sx={{ paddingBottom: "60px" }}>
                  {videostate === false && (

                      !videoFileDialogSelected && firstname!=='' && lastname!=='' && (validateEmail() || isPhoneValid()) ? <RecordButton
                        onClick={() => handleVideoRecordButton()}
                        variant="contained"
                        >
                        REC
                        </RecordButton>
                      : videoFile!=null?
                          <div>
                            <Button variant={'outlined'} onClick={() => handleVideoSubmit()}>Submit Video Review</Button>
                            <p style={{fontSize: '12px'}}>{videoFile.name}</p>
                          </div>
                             : <></>)}
                  {videostate === true && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        style={{
                          position: "relative",
                          width: "100%",
                          paddingBottom: "1.5rem",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          style={{
                            position: "absolute",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "90%",
                            opacity: ".7",
                            zIndex: "1",
                            color: "whitesmoke",
                          }}
                          onClick={(e) => {
                            clickUnmute(e, `video`, `mute`);
                          }}
                        >
                          <VolumeIconSVG sx={{}} />
                        </Box>

                        <video
                          id={`video`}
                          style={{
                            borderRadius: "50%",
                            objectFit: "cover",
                            margin: "0 auto",
                            backgroundColor: "black",
                            width: "150px",
                            height: "150px",
                            padding: "0",
                            display: "block",
                          }}
                          onClick={(e) => {
                            handleVideoClick(e, `mute`);
                          }}
                          src={videoURL}
                          tabIndex="-1"
                        ></video>
                      </Box>
                      <div>
                        <button
                          onClick={(e) => {
                            //if(localStorage.getItem("WiFIUploadsOnly")){
                            e.preventDefault();
                            handleVideoSubmit();
                            //handleShouldUploadOpen();
                          }}
                          style={{
                            marginRight: "10px",
                            display: "inline-block",
                            color: green[50],
                            background: green[500],
                            border: "0",
                            borderRadius: "25px",
                            padding: "10px 25px",
                          }}
                        >
                          Submit
                        </button>
                        <button
                          style={{
                            marginRight: "10px",
                            display: "inline-block",
                            color: red[50],
                            background: red[500],
                            border: "0",
                            borderRadius: "25px",
                            padding: "10px 25px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteReview();
                            refresh();
                          }}
                        >
                          Delete Review
                        </button>
                        <LegalJargon companyName={localStorage.getItem("companyname")} isHipaa={localStorage.getItem("hipaa")}/>
                      </div>
                    </div>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {snackbarMsg}
            </Alert>
          </Snackbar>

          {!error ?
              <StatusComponent
                  state={statusState}
                  message={"Review Submitted"}
                  status={"success"}
              />
              :
              <StatusComponent
                  state={statusState}
                  message={error}
                  status={"error"}
              />
          }
        </Box>
        <Dialog
          fullScreen
          open={videoDialog}
          onClose={handleVideoDialogClose}
          TransitionComponent={Transition}
        >
          <Recorder refresh={recorderRefresh} />
        </Dialog>
        {/*<ShouldUploadPrompt />*/}
      </Container>
    </>
  );
}