import React from "react"

export default function SingleStarOutlineSVG(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55"
        height="53"
        fill="none"
      >
        <path
          fill="#FAFDFF"
          stroke="#555555"
          strokeWidth="2"
          d="m53.2182 23.4234-.0001-.0002-.0111.0088-11.7592 9.2949c-.1392.1054-.2433.2506-.2985.4164-.0558.1674-.0591.3479-.0094.5173.0004.0014.0009.0028.0013.0042l4.0631 14.3398.0008.0028c.1815.6261.1646 1.293-.0483 1.9091-.2128.6157-.6109 1.1503-1.1395 1.5303-.5331.3791-1.1708.5827-1.8246.5827-.6543 0-1.2924-.2039-1.8257-.5834l.0001-.0002-.0115-.0077-12.3723-8.2986c-.0012-.0008-.0023-.0016-.0035-.0025-.1438-.0982-.3139-.1508-.4881-.1508-.1743 0-.3444.0526-.4881.1509-.0012.0008-.0024.0016-.0035.0024l-12.3723 8.2986-.0023.0016c-.5414.3675-1.1827.5591-1.8367.5489-.6539-.0102-1.289-.2217-1.8186-.6059-.5297-.3843-.9282-.9225-1.14147-1.5415-.21323-.619-.23073-1.2888-.0501-1.9182l.00062-.0022 4.03195-14.2968h.0001l.0018-.007c.0444-.1666.039-.3424-.0154-.5059-.0545-.1635-.1557-.3075-.2911-.4142l-.0002-.0002-11.7143-9.2157.00011-.0001-.01041-.0078c-.53298-.3974-.928387-.9517-1.130718-1.5854-.202333-.6337-.201417-1.3149.002618-1.948.204033-.6331.60093-1.1864 1.13497-1.5823.53404-.396 1.17845-.6146 1.84288-.6255l.00001.0001.01071-.0004 14.93323-.5646.0078-.0003.0078-.0005c.1697-.0117.3324-.0723.4684-.1744s.2396-.2413.2982-.4009l.0001-.0001 5.1437-13.99802.0003-.00087c.2241-.61343.6312-1.14306 1.166-1.51722C26.2025.700637 26.8393.5 27.4917.5c.6524 0 1.2892.200636 1.8239.57479.5348.37416.9419.90379 1.166 1.51723l.0007.0019 5.16 13.94728c.0586.1596.1622.2988.2982.4009.136.1021.2987.1627.4684.1744l.0078.0005.0078.0003 14.9332.5646v.0001l.0107.0002c.6645.0108 1.3089.2295 1.8429.6254.5341.3959.931.9492 1.135 1.5824.204.6331.2049 1.3143.0026 1.948-.2023.6337-.5977 1.188-1.1307 1.5854Z"
        />
      </svg>
    </>
  )
}
