let reviews = [
  {
    review_id: 1,
    review_date: "10/17/19",
    review_type: "video",
    review_origin: "sotellus",
    review: "",
    review_rating: 5,
    reviewerName: "Carol G",
    featured: true
  },
  {
    review_id: 2,
    review_date: "11/09/21",
    review_type: "written",
    review_origin: "google",
    review: "Great Demo! Thank you for taking a second time to show me the demo. I have started using the service for my business and am excited to see the results in the near future.",
    review_rating: 5,
    reviewerName: "John Denver",
    featured: false
  },
  {
    review_id: 3,
    review_date: "11/22/21",
    review_type: "written",
    review_origin: "facebook",
    review: "SoTellUs has provided amazing customer service. From the moment we requested assistance, our first interaction with Nick, to working with Issac has been absolutely amazing. They have exceeded our expectations of excellent customer service. We couldn’t have chosen a better company to work with. Thank you SoTellUs!!",
    review_rating: null,
    reviewerName: "Bob Ross",
    featured: true
  },
  {
    review_id: 4,
    review_date: "11/02/21",
    review_type: "video",
    review_origin: "sotellus",
    review: "",
    review_rating: 4,
    reviewerName: "Jay Rosen",
    featured: false
  },
  {
    review_id: 5,
    review_date: "10/30/21",
    review_type: "written",
    review_origin: "google",
    review: "Great information. Can't wait to learn more and get started.",
    review_rating: 4,
    reviewerName: "Isaac Cruz",
    featured: true
  },
  {
    review_id: 6,
    review_date: "07/17/21",
    review_type: "written",
    review_origin: "facebook",
    review: "I have seen and tried different strategies and methods even lost a lot of money while trying. Until i was introduce to this new strategy that got me back on track and i have been able to regain my lost money and still making consistent growth on all of my trade. Mrs Rachael Raschke is an amazing trader, she steadily lifted me out of financial penury. If you are interested and tired of losing your money You can reach her Whatsapp: +19418704911 Email: rachaelraschke397@gmail.com",
    review_rating: null,
    reviewerName: "Chimaobi Collins",
    featured: false
  },  
]

export function getReviews() {
  return reviews;
}