import React, { useState, useEffect, useRef, useContext } from "react";
// import { useHistory } from "react-router-dom"

import ReviewInviteBtn from "@ReviewInviteBtnSvg";
// import FlagSVG from "@FlagIcon";

import { useStyles } from "@ReviewInviteStyles";

import InputAdornment from "@mui/material/InputAdornment";
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
// import Fab from '@mui/material/Fab'
// import CheckIcon from '@mui/icons-material/Check'
// import SaveIcon from '@mui/icons-material/Save'

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import StatusComponent from "../../components/StatusView.component";
import { AppContext } from "../../utils/AppContext";
import parsePhoneNumber from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css'


export default function ReviewInvite() {
  const app = useContext(AppContext);
  const classes = useStyles();
  const [type, setType] = useState("both");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const timer = useRef();
  const [pendingReview, setPendingReview] = useState({});
  const [snackbarMsg, setSnackbarMsg] = useState("Complete All Fields");
  const [snackbarState, setSnackBarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const [statusState, setStatusState] = useState(false);
  const { vertical, horizontal, open } = snackbarState;



  useEffect(() => {

    setPendingReview((prev) => ({
      ...prev,
      firstname: firstname,
    }));
  }, [firstname]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      lastname: lastname,
    }));
  }, [lastname]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      mobile: mobile,
    }));
  }, [mobile]);
  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      email: email,
    }));
  }, [email]);

  const buttonSx = {
    borderRadius: "8px",
    backgroundColor: "#019d8e",
    width: "90%",
    "&:hover": {
      backgroundColor: "#006f61",
    },
    ...(success && {
      backgroundColor: "#4CAF50",
      "&:hover": {
        bgcolor: "#2e7d31",
      },
    }),
  };

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function isPhoneValid(){
    const phoneNumber = parsePhoneNumber(mobile, 'US')
    if(phoneNumber){
      return phoneNumber.isValid()
    }else{
      return false
    }
  }

  useEffect(()=>{

    if(firstname!=='' && lastname!=='' && (mobile!=='' || email!=='')){
      if(isPhoneValid() || validateEmail()){
        setReadyToSubmit(true)
      }else{
        setReadyToSubmit(false)
      }
    }else{
      setReadyToSubmit(false)
    }

  }, [firstname, lastname, mobile, email])

  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value);
  };
  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };
  const handleMobileChange = (mobile, country) => {
    setMobile(mobile);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  function validateEmail() {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }


  const handleTypeChange = (event, type) => {
    if (type !== null) {
      setType(type);
    }
  };
  const handleClose = () => {
    setSnackBarState({ ...snackbarState, open: false });
  };

  //ME = MobileEmail
  const isMEBlank = () => {
    if (pendingReview.mobile === "" && pendingReview.email === "") return true;
    return false;
  };

  const clearFields = () => {
    setFirstname("");
    setLastname("");
    setMobile("");
    setEmail("");
    setType("both");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      isMEBlank() ||
      Object.keys(pendingReview).length === 0 ||
      pendingReview.firstname === "" ||
      pendingReview.lastname === ""
    ) {
      setSnackBarState({
        open: true,
        vertical: "bottom",
        horizontal: "center",
        severity: "success",
      });
      return false;
    }

    var urlencoded = new URLSearchParams();
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    urlencoded.append("api_token", localStorage.getItem("api_token"));
    urlencoded.append("firstname", firstname);
    urlencoded.append("lastname", lastname);
    urlencoded.append("mobile", mobile);
    urlencoded.append("email", email);
    urlencoded.append("type", type);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_API_URL+"invites", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        console.log("Successful Attempt: \n", JSON.stringify(result));
        if (result.status === "success") {
          setStatusState(true);
          setTimeout(() => {
            setStatusState(false);
            clearFields();
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("Failed Attempt: \n", error);
      });
  };

  return (
    <>
      <CssBaseline />
      <Container fixed sx={{ paddingBottom: "68px" }}>
        <Box
          component="form"
          autoComplete="off"
          className={classes.inviteFormStyles}
          sx={{ flexGrow: 1, paddingBottom: "68px" }}
        >
          <Grid container direction="row">
            <Grid
              item
              xs={12}
              sx={{ padding: "11px 0px", textAlign: "center" }}
            >
              <Grid item xs={12} mb={2}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "12px",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "22px",
                    lineHeight: "133.4%",
                    color: "#444444",
                  }}
                >
                  Send out an invite
                </Typography>
              </Grid>
              <Grid item xs={12} mb={1}>
                <TextField
                  autoFocus
                  id="input-firstname"
                  value={firstname}
                  label="First Name"
                  onChange={handleFirstnameChange}
                  sx={{ width: "90%" }}
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&nbsp;</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  id="input-lastname"
                  value={lastname}
                  label="Last Name"
                  onChange={handleLastnameChange}
                  sx={{ width: "90%" }}
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&nbsp;</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid container item sx={{ width: "90%", display: 'inline-block', textAlign: 'left' }}>
                <Grid item xs={12} mb={1}>
                  <PhoneInput
                      country={"us"}
                      placeholder="Mobile"
                      autoFormat={localStorage.getItem("DisablePhoneAutoFormat")
                          ? !(localStorage.getItem("DisablePhoneAutoFormat"))
                          : true}
                      value={mobile}
                      style={{ marginBottom: "10px" }}
                      inputStyle={{width: '100%', height: '53px', backgroundColor: 'rgba(196, 196, 196, 0.15)'}}
                      onChange={(phone, country) =>
                          handleMobileChange(phone, country)
                      }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mb={2}>
                <TextField
                  label="Email"
                  onChange={handleEmailChange}
                  variant="filled"
                  sx={{ width: "90%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&nbsp;</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                container
                item
                direction="column"
                spacing={0}
                sx={{
                  width: "90%",
                  alignItems: "flex-start",
                  margin: "0 auto",
                }}
              >
                <Grid item xs={12} sx={{ marginBottom: "4px" }}>
                  <Typography
                    component="div"
                    sx={{ fontSize: "12px", color: "#8F8F8F" }}
                  >
                    Invite Type
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                  <ToggleButtonGroup
                    sx={{ justifyContent: "start" }}
                    value={type}
                    exclusive
                    onChange={handleTypeChange}
                  >
                    <ToggleButton
                      className={classes.inviteTypeBtn}
                      value="video"
                    >
                      Video
                    </ToggleButton>
                    <ToggleButton
                      className={classes.inviteTypeBtn}
                      value="written"
                    >
                      Written
                    </ToggleButton>
                    <ToggleButton
                      className={classes.inviteTypeBtn}
                      value="both"
                    >
                      Both
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid item xs={12} pb={1}>
                <Button
                    disabled={!readyToSubmit}
                  type="submit"
                  onClick={handleSubmit}
                  sx={buttonSx}
                  fullWidth
                  variant="contained"
                >
                  <ReviewInviteBtn />
                  &nbsp;Send Invite
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {snackbarMsg}
          </Alert>
        </Snackbar>
        <StatusComponent
          state={statusState}
          message={"Invite Sent!"}
          status={"success"}
        />
      </Container>
    </>
  );
}

