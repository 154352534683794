import React from 'react';
import { Switch, Route } from "react-router-dom"
import ReviewCardFiveStarRating from "@ReviewsCardFiveStarRating"
import ReviewCardFourStarRating from "@ReviewsCardFourStarRating"
import ReviewCardThreeStarRating from "@ReviewsCardThreeStarRating"
import ReviewCardTwoStarRating from "@ReviewsCardTwoStarRating"
import ReviewCardOneStarRating from "@ReviewsCardOneStarRating"
import FacebookLogo from "../assets/logos/SquareFacebookLogo.svg"
import SoTellUsLogo from "../assets/logos/SoTellUsLogo.svg"
import GoogleLogo from "../assets/logos/GoogleLogo.svg"
import Slide from "@mui/material/Slide"

export default function SlideRight(props) { return <Slide {...props} direction="right" /> }

export const mobileTruncate = (input) =>
  input.length > 50 ? `${input.substring(0, 75)}...` : input

export const miniMobileTruncate = (input) =>
  input.length > 25 ? `${input.substring(0, 25)}...` : input

export const desktopTruncate = (input) =>
  input.length > 200 ? `${input.substring(0, 200)}...` : input

export const getCustomerInitials = (firstname, lastname, displayname) => {
  let result = ''
  if(firstname){
    result = `${firstname.charAt(0)} `
    if(lastname) result = result + lastname.charAt(0);
  }
  else if(displayname){
    let dn = displayname.split(" ");
    result = dn[0].charAt(0);
    if(dn[1]) result = result + dn[1].charAt(0);
  }
  else{
    result = "C"
  }
  return result.toUpperCase()
}

export const getVisitorFirstNameLastInitial = (input) => {
  const simplifyInput = input.split(" ")
  let firstName = simplifyInput[0]
  let lastName = simplifyInput[simplifyInput.length - 1]
  if (firstName.length) {
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
  }
  if (lastName.length) {
    lastName = lastName.charAt(0).toUpperCase()+"."
  }
  return `${firstName} ${lastName}`
}

export const getVisitorFirstName = (input) => {
  const simplifyInput = input.split(" ")
  let firstName = simplifyInput[0]
  if (firstName.length) {
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
  }
  return `${firstName}`
}

export const handleStarRating = (input) => {
  let rating = input
  let valueType = typeof rating
  
  if(valueType === "number") {
    if (rating === 5) {
      return <ReviewCardFiveStarRating />
    } else if (rating === 4) {
      return <ReviewCardFourStarRating />
    } else if (rating === 3) {
      return <ReviewCardThreeStarRating />
    } else if (rating === 2) {
      return <ReviewCardTwoStarRating />
    } else {
      return <ReviewCardOneStarRating />
    }
  }
  return null
}

export const handleReviewCardHeaderText = (origin, rating) => {
  let reviewOrigin = origin
  let reviewRating = rating
  let text
  if (reviewOrigin) {
    if (reviewOrigin === "facebook") {
      text = "recommends SoTellUs Facebook"
    } else {
      text = `gave SoTellUs a ${reviewRating} star Review`
    }
    return text
  }
  return null
}

export const handleCompanyLogo = (origin) => {
  let reviewOrigin = origin
  if (reviewOrigin) {
    if (reviewOrigin === "sotellus" || reviewOrigin === 'stu' ||  reviewOrigin === 'STU') {
      return <SoTellUsLogo />
    } else if (reviewOrigin === "google" || reviewOrigin === "gmb" || reviewOrigin === "GMB") {
      return <GoogleLogo />
    } else if (reviewOrigin === "facebook" || reviewOrigin === "fbp" || reviewOrigin === "FBP") {
      return <FacebookLogo />
    }
  }
  return null
}

export function HeaderTitle() {
  return (
    <Switch>
      <Route exact path="/gallery">Gallery</Route>
      <Route exact path='/reviews'>Reviews</Route>
      <Route exact path='/chat'>Chat</Route>
      <Route exact path='/statistics'>Stats</Route>
      <Route exact path='/settings'>Settings</Route>
      <Route path='/reviews/invite'>Review Invite</Route>
      <Route path='/reviews/video'>Video Review</Route>
      <Route path='/reviews/written'>Written Review</Route>
      <Route path='/chat/inbox/conversation/:conversation_id'>Live Chat</Route>
      <Route path='/chat/closed/conversation/:conversation_id'>Closed Chat</Route>
    </Switch>  
  )
}