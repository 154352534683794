import { chatConversationsState } from "./atoms";
import { selector } from "recoil";

export const getChatUnreadConversations = selector({
  key: "getUnreadConversations",
  get: ({ get }) => {
    const chatConversations = get(chatConversationsState);
    const getUnreadConversations = chatConversations.map(conversation => conversation.unread)
    const unreadConversationCount = getUnreadConversations.reduce((a, b) => a + b)
    return unreadConversationCount;
  },
});