import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles({
  starRating: {
    verticalAlign: "middle",
    justifyContent: "center",
    margin: "0 auto",
    "& label:first-of-type": {
      marginLeft: "0px",
    },
    "& label:nth-last-of-type(2)": {
      marginRight: "0px",
    },
    "& label:last-of-type": {
      margin: "0px",
    },
    "& label": {
      margin: "0px 2px",
      "& .MuiRating-icon": {
        height: "57px",
        width: "57px",
      },
    },
  },
})
