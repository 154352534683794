import React from "react";

export default function BackButtonSVG() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="none">
                <path stroke="url(#backBtn1)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4.5" d="m19.2382 6.38235-9.2088 9.20885L19.2382 24.8"/>
                <defs>
                    <linearGradient id="backBtn1" x1="2.07345" x2="80.4039" y1="29.0283" y2="-46.0768" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#686767"/>
                        <stop offset="1" stopColor="#fff"/>
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
}
