import React, { useState, useEffect, useContext, useCallback } from "react";

import "@fontsource/roboto";
import StarRating from "@Rating";
import ExampleLogo from "@ExampleCompanyLogo";
import WrittenReviewSubmitSVG from "@WrittenReviewSubmitSvg";
import { useStyles } from "@WrittenReviewStyles";
import { useHistory, useLocation } from "react-router-dom";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import StatusComponent from "../../components/StatusView.component";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { AppContext } from "../../utils/AppContext";
import parsePhoneNumber from "libphonenumber-js";
import LegalJargon from "../../components/LegalJargon";

export default function WrittenReview(props) {
  const app = useContext(AppContext);
  const history = useHistory();
  let api_token = localStorage.getItem("api_token");
  const classes = useStyles();

  const [hasSavedPR, setHasSavedPR] = useState(parseInt(app.reviewID) === -1 ? false:true);
  const [PRID, setPRID] = useState(
    parseInt(app.reviewID) === -1
      ? new Date().getTime()
      : parseInt(app.reviewID)
  );

  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [starRating, setStarRating] = useState(0);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [verification, setVerificationMethod] = useState("text");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [statecode, setStateCode] = useState(
    localStorage.getItem("DefaultCountry")
      ? localStorage.getItem("DefaultCountry")
      : "us"
  );
  const [review, setWrittenReview] = useState("");

  const [confirm, setConfirmation] = useState(false);

  const [formDisplay, showFormDisplay] = useState(false);
  //*! API PARAMS - TOKEN,FIRSTNAME,LASTNAME,MOBILE,EMAIL,STARS,STARWEIGHT,TYPE,REVIEW
  const [pendingReview, setPendingReview] = useState({
    type: "text",
    starweight: 2,
  });
  const [snackbarMsg, setSnackbarMsg] = useState("Complete All Fields");
  const [snackbarState, setSnackBarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const [statusState, setStatusState] = useState(false);
  const { vertical, horizontal, open } = snackbarState;

  useEffect(() => {
    //init PRS if needed
    if (!localStorage.getItem("PendingReviews"))
      localStorage.setItem("PendingReviews", JSON.stringify([{}]));
    if (parseInt(app.reviewID) === -1) {
      console.log("NOT Implementing Saved Pending Review");
      setPendingReview((prev) => ({
        ...prev,
        token: api_token,
        PRID: PRID,
      }));
    }
  }, []);

  useEffect(() => {
    //checks if PR before implementation
    implementSavedPendingReview();
  }, [app.reviewID]);

  //*! PendingReview Updated - only starts saving after input of first name
  useEffect(() => {
    if (pendingReview.PRID !== undefined && pendingReview.firstname) {
      if(!hasSavedPR){
        app.setPRWrittenCount(app.PRWrittenCount + 1);
        setHasSavedPR(true);
      }
      savePendingReview();
    }
  }, [pendingReview]);

  useEffect(()=>{
    if(firstname=='' || lastname==''){
      setReadyToSubmit(false)
    }
    if(verification=='text'){
      if(!mobile || !isPhoneValid()){
        setReadyToSubmit(false)

      }
    }else{
      if(email==''){
        setReadyToSubmit(false)
      }
    }

    if(firstname!='' && lastname!='' && ((mobile && isPhoneValid()) || (email && email!=''))){
      setReadyToSubmit(true)
    }

  }, [firstname, lastname, verification, mobile, email])

  function isPhoneValid(){
    const phoneNumber = parsePhoneNumber(mobile, 'US')
    if(phoneNumber){
      return phoneNumber.isValid()
    }else{
      return false
    }
  }

  const savePendingReview = () => {
    //get current PRS
    let PRS = JSON.parse(localStorage.getItem("PendingReviews"));
    if (PRS.length === 1) {
      PRS = PRS[0];
    }
    PRS[pendingReview.PRID] = pendingReview;
    //SAVE updated PRS
    localStorage.setItem("PendingReviews", JSON.stringify(PRS));
  };
  const removePendingReview = (PRID) => {
    let PRS = JSON.parse(localStorage.getItem("PendingReviews"));
    if (PRS.length === 1) {
      PRS = PRS[0];
    }
    delete PRS[PRID];

    localStorage.setItem("PendingReviews", JSON.stringify(PRS));
  };
  const implementSavedPendingReview = () => {
    if (parseInt(app.reviewID) !== -1) {
      let prs = JSON.parse(localStorage.getItem("PendingReviews"));
      if (prs.length === 1) prs = prs[0];
      Object.keys(prs).forEach((i) => {
        let pr = prs[i];
        if (pr.PRID.toString() === app.reviewID) {
          console.log("Implementing", pr);
          showFormDisplay(true);
          inputAllFields(pr);
          setPendingReview(pr);
          return;
        }
      });
    }
  };

  const inputAllFields = (pr) => {
    setStarRating(pr.stars);
    setFirstName(pr.firstname);
    setLastName(pr.lastname);
    setVerificationMethod(pr.verificationmethod);
    setMobile(pr.mobile);
    setEmail(pr.email);
    setWrittenReview(pr.review);
  };

  useEffect(() => {
    if (starRating) {
      if (starRating >= 1) {
        showFormDisplay(true);
      }
    }
    setPendingReview((prev) => ({
      ...prev,
      stars: starRating,
    }));
  }, [starRating]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      firstname: firstname,
    }));
  }, [firstname]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      lastname: lastname,
    }));
  }, [lastname]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      mobile: mobile,
    }));
  }, [mobile]);
  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      email: email,
    }));
  }, [email]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      verificationmethod: verification,
    }));
  }, [verification]);

  useEffect(() => {
    setPendingReview((prev) => ({
      ...prev,
      review: review,
    }));
  }, [review]);

  const handleClose = () => {
    setSnackBarState({ ...snackbarState, open: false });
  };
  const handleRatingChange = (starRatingData) => {
    setStarRating(starRatingData);
    showFormDisplay(true);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleVerificationChange = (event, response) => {
    if (response !== null) {
      setVerificationMethod(response);
    }
  };
  const handleMobileChange = (phone, country) => {
    setMobile(phone);
    setStateCode(country.countryCode);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleReviewChange = (event) => {
    setWrittenReview(event.target.value);
  };

  const toBoolean = (str) => {
    if (str === "true") return true;
    return false;
  };
  //ME = MobileEmail
  const isMEBlank = () => {
    if (pendingReview.mobile === "" && pendingReview.email === "") return true;
    return false;
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setVerificationMethod("text");
    setMobile("");
    setEmail("");
    setWrittenReview("");
  };

  const handleSubmit = async () => {
    console.log(pendingReview);
    if (isMEBlank()) {
      setSnackBarState({
        open: true,
        vertical: "bottom",
        horizontal: "center",
        severity: "success",
      });
      return false;
    }
    var formBody = [];
    for (var property in pendingReview) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(pendingReview[property]);
      if (
        encodedValue === "" &&
        encodedKey != "mobile" &&
        encodedKey != "email"
      ) {
        setSnackBarState({
          open: true,
          vertical: "bottom",
          horizontal: "center",
          severity: "success",
        });
        return false;
      }
      if (encodedKey != "PRID" && encodedKey != "verificationmethod")
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    try {
      let response = await fetch(process.env.REACT_APP_API_URL+`reviews`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      });
      let result = await response.json();
      if (result.status == "success") {
        setStatusState(true);
        setTimeout(() => {
          setStatusState(false);
          clearFields();
          removePendingReview(pendingReview.PRID);
          app.setReviewID(-1);
          app.setPRWrittenCount(app.PRWrittenCount - 1);
          document.getElementById("back-button").click();
        }, 3000);
      } else {
        //unsuccessful submission
      }
    } catch (error) {
      console.log(error);
    }
  };

  const LogoURL = localStorage.getItem("logo_url") ?? null

  return (
    <>
      <CssBaseline />
      <Container fixed>
        <Box
          component="form"
          autoComplete="off"
          className={
            formDisplay ? classes.writtenFormStyles : classes.ratingDisplay
          }
        >
          <Grid
            container
            direction="column"
            sx={{ padding: "11px 0px", textAlign: "center" }}
          >
            {!formDisplay && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "16px",
                }}
              >
                {LogoURL?
                    <img src={LogoURL} width={300}/>
                    : <ExampleLogo/>}
              </Box>
            )}
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12px",
                justifyContent: "center",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "22px",
                lineHeight: "133.4%",
                color: "#444444",
              }}
            >
              How was your experience?
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 6px 16px",
              }}
            >
              <StarRating
                value={starRating}
                handleRatingChange={handleRatingChange}
              />
            </Box>
            {formDisplay && (
              <>
                <Grid item xs={12} mb={1}>
                  <TextField
                    label="First Name"
                    sx={{ width: "90%" }}
                    variant="filled"
                    value={firstname}
                    onChange={handleFirstNameChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">&nbsp;</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    label="Last Name"
                    sx={{ width: "90%" }}
                    variant="filled"
                    onChange={handleLastNameChange}
                    value={lastname}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">&nbsp;</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  spacing={0}
                  sx={{
                    width: "90%",
                    alignItems: "flex-start",
                    margin: "0 auto",
                  }}
                >
                  <Grid item xs={12} sx={{ marginBottom: "4px" }}>
                    <Typography
                      component="div"
                      sx={{ fontSize: "12px", color: "#8F8F8F" }}
                    >
                      Verification Type
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <ToggleButtonGroup
                      sx={{ justifyContent: "flex-start" }}
                      value={verification}
                      exclusive
                      onChange={handleVerificationChange}
                    >
                      <ToggleButton
                        className={classes.writtenVerificationBtn}
                        value="text"
                      >
                        Text
                      </ToggleButton>
                      <ToggleButton
                        className={classes.writtenVerificationBtn}
                        value="email"
                      >
                        Email
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                {verification === "text" || verification === "call" ? (
                    <Grid container item sx={{ width: "auto", margin: "0 auto" }}>
                      <Grid item xs={12} mb={1}>
                        <PhoneInput
                            country={statecode}
                            placeholder=""
                            autoFormat={localStorage.getItem("DisablePhoneAutoFormat")
                                ? !toBoolean(localStorage.getItem("DisablePhoneAutoFormat"))
                                : true}
                            value={mobile}
                            style={{ marginBottom: "10px" }}
                            onChange={(phone, country) =>
                                handleMobileChange(phone, country)
                            }
                        />
                      </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12} mb={1}>
                      <TextField
                          label="Email"
                          variant="filled"
                          sx={{ width: "90%" }}
                          onChange={handleEmailChange}
                          value={email}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  &nbsp;
                                </InputAdornment>
                            ),
                          }}is
                      />
                    </Grid>
                )}
                <Grid item xs={12} mb={3}>
                  <TextField
                    label="Written Review"
                    multiline
                    rows={3}
                    variant="filled"
                    sx={{ width: "90%" }}
                    value={review}
                    onChange={handleReviewChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">&nbsp;</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingBottom: "35px" }}>
                  <Button
                    disabled={!readyToSubmit}
                    fullWidth={true}
                    onClick={() => handleSubmit()} //console.log(pendingReview)
                    variant="contained"
                    className={classes.submitWrittenReviewBtn}
                  >
                    <WrittenReviewSubmitSVG />
                    &nbsp;Submit Review
                  </Button>
                </Grid>
                <LegalJargon companyName={localStorage.getItem("companyname")} isHipaa={localStorage.getItem("hipaa")}/>
              </>
            )}
          </Grid>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {snackbarMsg}
            </Alert>
          </Snackbar>
          <StatusComponent
            state={statusState}
            message={"Review Submitted"}
            status={"success"}
          />
        </Box>
      </Container>
    </>
  );
}
