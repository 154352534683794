import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  actionsWrap: {
    height: "76px",
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: "10px",
    borderTopLeftRadius: "12px", 
    borderTopRightRadius: "12px", 
    backgroundColor: "#eff2f4", 
    boxShadow: "inset .5px .5px 2px #4f6573",
    display: "flex",
    flexDirection: "row",
    zIndex: 1000,
  },
  actionsDisabledWrap: {
    height: "60px",
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: "5px",
    borderTopLeftRadius: "12px", 
    borderTopRightRadius: "12px", 
    backgroundColor: "#eff2f4", 
    boxShadow: "inset .5px .5px 2px #4f6573",
    color: "#5D5D5D",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  actions: {
    marginLeft: "7px",
    alignSelf: "center",
  },
  sendMsgBtn : {
    height: "54px",
    minWidth: "48px",
    width: "48px",
    maxWidth: "48px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 1.5px 1.5px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%)",
    color: "#222",
    borderRadius: "10px",
    "&:hover": { backgroundColor: "#f3f3f3" }
  }
}))