import React from "react";

export default function WrittenReviewSubmitSVG() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                <path fill="#fff" d="m18.2113 3.24706-.8334-.83333c-.0386-.03871-.0846-.06942-.1351-.09037-.0506-.02095-.1047-.03173-.1594-.03173-.0548 0-.109.01078-.1595.03173-.0505.02095-.0965.05166-.1352.09037l-5 5c-.0387.03867-.0693.08459-.0903.13514a.4155408.4155408 0 0 0-.0318.15944v.83335c0 .11048.044.21648.1221.29464.0782.07808.1841.122.2947.122h.8332c.0548 0 .1089-.0108.1595-.03168.0505-.02096.0965-.05168.1352-.0904l5-4.99999c.0386-.03868.0693-.0846.0903-.13515.021-.05054.0318-.10472.0318-.15944a.415578.415578 0 0 0-.0318-.15944c-.021-.05054-.0517-.09647-.0903-.13514Z" />
                <path fill="#fff" d="M12.9166 9.79172h-.8332c-.3315-.0004-.6492-.13216-.8836-.36656-.2343-.23432-.3661-.55208-.3664-.88344v-.83335c-.0004-.1642.0317-.32685.0946-.47856.0628-.15169.1551-.28943.2715-.40523l2.8663-2.86621H3.75001c-.55235.00059-1.08192.22027-1.4725.61084-.39057.39058-.61025.92014-.61084 1.4725v7.49999c.00059.5523.22027 1.0819.61084 1.4725.39058.3906.92015.6102 1.4725.6109h3.75v2.0833c0 .0773.02154.1532.06223.219.04068.0658.09889.119.1681.1537.0692.0345.14668.0492.22376.0423.0771-.007.1507-.0353.21254-.0817l3.22276-2.4166h4.0272c.5524-.0007 1.082-.2203 1.4726-.6109.3906-.3906.6102-.9202.6108-1.4725V5.72596l-3.6995 3.69952c-.1159.11648-.2535.20872-.4053.2716-.1517.06288-.3143.09504-.4786.09464ZM4.58334 6.45837H8.75c.11048 0 .21648.04391.29464.12204.07816.07815.122.18412.122.29463s-.04384.21649-.122.29463c-.07816.07814-.18416.12204-.29464.12204H4.58334c-.11051 0-.21648-.0439-.29463-.12204-.07813-.07814-.12204-.18412-.12204-.29463s.04391-.21648.12204-.29463c.07815-.07813.18412-.12204.29463-.12204Zm0 2.49999H8.75c.11048 0 .21648.04392.29464.12208.07816.07808.122.18408.122.29464 0 .11048-.04384.21648-.122.29456-.07816.07816-.18416.12208-.29464.12208H4.58334c-.11051 0-.21648-.04392-.29463-.12208-.07813-.07808-.12204-.18408-.12204-.29456 0-.11056.04391-.21656.12204-.29464.07815-.07816.18412-.12208.29463-.12208ZM15 11.8751c0 .1105-.0439.2165-.122.2945-.0782.0782-.1842.1221-.2946.1221H4.58334c-.11051 0-.21648-.0439-.29463-.1221-.07813-.078-.12204-.184-.12204-.2945 0-.1106.04391-.2166.12204-.2947.07815-.0781.18412-.122.29463-.122H14.5834c.1104 0 .2164.0439.2946.122.0781.0781.122.1841.122.2947Z" />
            </svg>
        </>
    );
}
