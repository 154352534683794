import { atom } from "recoil"

export const chatInboxState = atom({
    key: "chatInboxState",
    default: true
})

export const chatClosedState = atom({
  key: "chatClosedState",
  default: false
})

export const transitionState = atom({
  key: "transitionState",
  default: undefined
})

export const snackbarState = atom({
  key: "snackbarState",
  default: { open: false, vertical: "top", horizontal: "center" }
})

export const chatConversationsState = atom({
  key: "chatConversationsState",
  default: [
  {
    badgeColor: "warning",
    unread: 1,
    conversation_id: 12,
    client_id: 21,
    contactOptInTimestamp: 1519129858900,
    callOptIn: 1,
    smsOptIn: 0,
    emailOptIn: 0,
    accepted: 1,
    active: "open",
    sentByContact: 0,
    leftContactDetails: false,
    contactMethod: null,
    messages: [
      {message_id: 156, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "1 hour ago"},
      {message_id: 157, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "43 minutes ago"},
      {message_id: 158, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "37 minutes ago"},
      {message_id: 159, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "35 minutes ago"},
      {message_id: 160, sent_by_customer: 0, message: "at in tellus integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque sit amet porttitor eget dolor morbi non arcu risus quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit duis tristique", timestamp: "28 minutes ago"},
    ],
    name: "Troy Howard",
    type: "secondary",
    avatarColor: "#F0C476",
    hoverColor: "rgba(240, 196, 118, .15)",
    sent: "2 mins ago",
  },
  {
    badgeColor: "warning",
    unread: 0,
    conversation_id: 77,
    client_id: 66,
    contactOptInTimestamp: null,
    callOptIn: 0,
    smsOptIn: 0,
    emailOptIn: 0,
    accepted: 1,
    active: "open",
    sentByContact: 1,
    leftContactDetails: false,
    contactMethod: null,
    messages: [
      {message_id: 106, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "1 hour ago"},
      {message_id: 107, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "43 minutes ago"},
      {message_id: 108, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "37 minutes ago"},
      {message_id: 109, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "35 minutes ago"},
      {message_id: 110, sent_by_customer: 0, message: "ipsum nunc aliquet bibendum enim", timestamp: "2 minutes ago"},
      {message_id: 111, sent_by_customer: 1, message: "sit amet dictum sit amet justo donec enim diam vulputate ut pharetra sit amet aliquam id diam maecenas ultricies mi", timestamp: "Moments ago"},
      {message_id: 112, sent_by_customer: 1, message: "congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque?", timestamp: "Just now"}
    ],
    name: "Trevor Howard",
    type: "primary",
    avatarColor: "#7BC68F",
    hoverColor: "rgba(52, 168, 83, .15)",
    sent: "9 mins ago",
  },
  {
    badgeColor: "warning",
    unread: 1,
    conversation_id: 62,
    client_id: 26,
    contactOptInTimestamp: 1519129858900,
    callOptIn: 0,
    smsOptIn: 1,
    emailOptIn: 0,
    accepted: 1,
    active: "open",
    sentByContact: 1,
    leftContactDetails: false,
    contactMethod: null,
    messages: [
      {message_id: 113, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "1 hour ago"},
      {message_id: 114, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "43 minutes ago"},
      {message_id: 115, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "37 minutes ago"},
      {message_id: 116, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "35 minutes ago"},
      {message_id: 117, sent_by_customer: 1, message: "at in tellus integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque sit amet porttitor eget dolor morbi non arcu risus quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit duis tristique", timestamp: "28 minutes ago"},
      {message_id: 118, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "12 minutes ago"},
      {message_id: 119, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "4 minutes ago"},
      {message_id: 120, sent_by_customer: 0, message: "ipsum nunc aliquet bibendum enim", timestamp: "2 minutes ago"},
      {message_id: 121, sent_by_customer: 0, message: "sit amet dictum sit amet justo donec enim diam vulputate ut pharetra sit amet aliquam id diam maecenas ultricies mi", timestamp: "Moments ago"},
      {message_id: 122, sent_by_customer: 1, message: "congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque?", timestamp: "Just now"},
      {message_id: 213, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "1 hour ago"},
      {message_id: 214, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "43 minutes ago"},
      {message_id: 215, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "37 minutes ago"},
      {message_id: 216, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "35 minutes ago"},
      {message_id: 217, sent_by_customer: 1, message: "at in tellus integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque sit amet porttitor eget dolor morbi non arcu risus quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit duis tristique", timestamp: "28 minutes ago"},
      {message_id: 218, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "12 minutes ago"},
      {message_id: 219, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "4 minutes ago"},
      {message_id: 220, sent_by_customer: 0, message: "ipsum nunc aliquet bibendum enim", timestamp: "2 minutes ago"},
      {message_id: 221, sent_by_customer: 0, message: "sit amet dictum sit amet justo donec enim diam vulputate ut pharetra sit amet aliquam id diam maecenas ultricies mi", timestamp: "Moments ago"},
      {message_id: 222, sent_by_customer: 1, message: "congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque?", timestamp: "Just now"}
    ],
    name: "Richard Johnson",
    type: "error",
    avatarColor: "#EF847C",
    hoverColor: "rgba(234, 67, 53, .15)",
    sent: "28 mins ago",
  },
  {
    badgeColor: "warning",
    unread: 0,
    conversation_id: 53,
    client_id: 35,
    contactOptInTimestamp: null,
    callOptIn: 0,
    smsOptIn: 0,
    emailOptIn: 0,
    accepted: 1,
    active: "open",
    sentByContact: 1,
    leftContactDetails: false,
    contactMethod: null,
    messages: [
      {message_id: 123, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "1 hour ago"},
      {message_id: 124, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "43 minutes ago"},
      {message_id: 125, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "37 minutes ago"},
      {message_id: 126, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "35 minutes ago"},
      {message_id: 127, sent_by_customer: 0, message: "at in tellus integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque sit amet porttitor eget dolor morbi non arcu risus quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit duis tristique", timestamp: "28 minutes ago"},
      {message_id: 128, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "12 minutes ago"},
    ],
    name: "Mark Vainauskas",
    type: "secondary",
    avatarColor: "#F0C476",
    hoverColor: "rgba(240, 196, 118, .15)",
    sent: "57 mins ago",
  },

  {
    badgeColor: "warning",
    unread: 1,
    conversation_id: 48,
    client_id: 84,
    contactOptInTimestamp: null,
    callOptIn: 0,
    smsOptIn: 0,
    emailOptIn: 0,
    accepted: 1,
    active: "closed",
    sentByContact: 1,
    leftContactDetails: false,
    contactMethod: null,
    messages: [
      {message_id: 135, sent_by_customer: 0, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "37 minutes ago"},
      {message_id: 136, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "35 minutes ago"},
      {message_id: 137, sent_by_customer: 0, message: "at in tellus integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque sit amet porttitor eget dolor morbi non arcu risus quis varius quam quisque id diam vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit duis tristique", timestamp: "28 minutes ago"},
      {message_id: 138, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "12 minutes ago"},
      {message_id: 139, sent_by_customer: 1, message: "enim ut sem viverra aliquet eget sit amet tellus cras", timestamp: "4 minutes ago"},
      {message_id: 140, sent_by_customer: 0, message: "ipsum nunc aliquet bibendum enim", timestamp: "2 minutes ago"},
      {message_id: 141, sent_by_customer: 0, message: "sit amet dictum sit amet justo donec enim diam vulputate ut pharetra sit amet aliquam id diam maecenas ultricies mi", timestamp: "Moments ago"},
      {message_id: 142, sent_by_customer: 1, message: "congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque?", timestamp: "Just now"}
    ],
    name: "Nick South",
    type: "secondary",
    avatarColor: "#7BC68F",
    hoverColor: "rgba(52, 168, 83, .15)",
    sent: "57 mins ago",
  },
  {
    badgeColor: "warning",
    unread: 0,
    conversation_id: 4,
    client_id: 8,
    contactOptInTimestamp: 1519129858900,
    callOptIn: 0,
    smsOptIn: 0,
    emailOptIn: 1,
    accepted: 1,
    active: "closed",
    sentByContact: 1,
    leftContactDetails: true,
    contactMethod: "text",
    messages: [
      {message_id: 96, sent_by_customer: 0, message: "Hello Brandon, how can we help you?", timestamp: "1 hour ago"},
      {message_id: 97, sent_by_customer: 1, message: "I need prices!", timestamp: "43 minutes ago"},
      {message_id: 98, sent_by_customer: 0, message: "Brandon wants you to send them a text message! Click the link or copy their phone number:\n14804804800", timestamp: "37 minutes ago"}
    ],
    name: "Brandon Craig",
    type: "primary",
    avatarColor: "#EF847C",
    hoverColor: "rgba(234, 67, 53, .15)",
    sent: "3 hrs ago",
  },
  {
    badgeColor: "warning",
    unread: 1,
    conversation_id: 34,
    client_id: 43,
    contactOptInTimestamp: null,
    callOptIn: 0,
    smsOptIn: 0,
    emailOptIn: 0,
    accepted: 1,
    active: "closed",
    sentByContact: 1,
    leftContactDetails: true,
    contactMethod: "call",
    messages: [
      {message_id: 96, sent_by_customer: 0, message: "Hello Isaac, how can we help you?", timestamp: "1 hour ago"},
      {message_id: 97, sent_by_customer: 1, message: "I wanted to know what your monthly promos were for Decemeber", timestamp: "43 minutes ago"},
      {message_id: 98, sent_by_customer: 0, message: "Isaac wants you to call them! Click the link or copy their phone number:\n14804804800", timestamp: "37 minutes ago"}
    ],
    name: "Isaac Cruz",
    type: "error",
    avatarColor: "#F0C476",
    hoverColor: "rgba(240, 196, 118, .15)",
    sent: "7 hrs ago",
  },
  {
    badgeColor: "warning",
    unread: 0,
    conversation_id: 26,
    client_id: 62,
    contactOptInTimestamp: 1519129858900,
    callOptIn: 0,
    smsOptIn: 0,
    emailOptIn: 1,
    accepted: 1,
    active: "closed",
    sentByContact: 1,
    leftContactDetails: true,
    contactMethod: "email",
    messages: [
      {message_id: 96, sent_by_customer: 0, message: "Hello Bob, how can we help you?", timestamp: "1 hour ago"},
      {message_id: 97, sent_by_customer: 1, message: "Please give me a call as soon as you can, thanks!", timestamp: "43 minutes ago"},
      {message_id: 98, sent_by_customer: 0, message: "Bob wants you to email them! Click the link or copy their email address:\nthat_guy_bob_24_7@gmail.com", timestamp: "37 minutes ago"}
    ],
    name: "Bob Hill",
    type: "primary",
    avatarColor: "#7BC68F",
    hoverColor: "rgba(52, 168, 83, .15)",
    sent: "3 hrs ago",
  },
]
})