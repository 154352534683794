import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  SettingsBottom,
  CountryToggle,
  CustomSwitch,
  SettingsItem,
} from "@SettingsStyles";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import PhoneCode from "react-phone-code";
export default function Settings() {
  const history = useHistory();

  const toBoolean = (str) => {
    if (str === "true") return true;
    return false;
  };
  const settings = [
    "AutoPostReviews",
    "WiFiUploadsOnly",
    "DefaultCountry",
    "DisablePhoneValidation",
    "DisablePhoneAutoFormat",
    "ShowDebuggingMessages",
  ];
  const [state, setState] = useState({
    AutoPostReviews: toBoolean(localStorage.getItem("AutoPostReviews")),
    WiFiUploadsOnly: toBoolean(localStorage.getItem("WiFiUploadsOnly")),
    DefaultCountry: localStorage.getItem("DefaultCountry") ? localStorage.getItem("DefaultCountry") : "us",
    DisablePhoneValidation: toBoolean(
      localStorage.getItem("DisablePhoneValidation")
    ),
    DisablePhoneAutoFormat: toBoolean(
      localStorage.getItem("DisablePhoneAutoFormat")
    ),
    ShowDebuggingMessages: toBoolean(
      localStorage.getItem("ShowDebuggingMessages")
    ),
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handleMobileChange = (phone, country) => {
    setState({
        ...state,
        "DefaultCountry" : country.countryCode
    });
  };
   function elementReady(selector) {
    return null
  }

  const logoutUser = (e) => {
    if (localStorage.getItem("api_token")) {
      localStorage.removeItem("api_token");
      history.push("/");
      history.go(0);
    } else {
      return null;
    }
  };

  return (
    <>
      <CssBaseline />
      <Container
        fixed
        sx={{
          position: "absolute",
          top: "9%",
          bottom: 0,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid container direction="column">
          <Grid item xs={12}>
            <Stack
              spacing={2}
              direction="column"
              divider={
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{ borderColor: "rgba(68, 68, 68, 0.38)" }}
                />
              }
              sx={{ paddingTop: "13px" }}
            >
              <Grid item xs={12}>
                <Box sx={{ display: "flex" }}>
                  <SettingsItem sx={{ alignSelf: "center" }}>
                    <Typography variant="subtitle1" component="div">
                      Logout
                    </Typography>
                  </SettingsItem>
                  <Box
                    sx={{
                      width: "25%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={logoutUser}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#EA5C50",
                        borderRadius: "8px",
                        "&:focus": { background: "#EA5C50", color: "#FFFFFF" },
                        "&:active": { background: "#EA5C50", color: "#FFFFFF" },
                        "&:hover": { background: "#EA5C50" },
                      }}
                    >
                      <LogoutRoundedIcon />
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
