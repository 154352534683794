import React, { useState, useEffect } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"

import ActiveHomeSVG from "@ActiveHomeSvg"
import InactiveHomeSVG from "@InactiveHomeSvg"
import ActiveSettingsSVG from "@ActiveSettingsSvg"
import InactiveSettingsSVG from "@InactiveSettingsSvg"

import { useStyles, BottomNavBtn } from "@BottomNavStyles"

import BottomNavigation from "@mui/material/BottomNavigation"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"

export default function BottomNav() {
  const classes = useStyles()

  let currentPage = useLocation()
  let { pathname } = useLocation()

  const [bottomNavStatus, setBottomNavStatus] = useState("")

  useEffect(() => {
    const currentLocation = locationMap.find(
      (item) => item.path === currentPage.pathname
    )
    if (currentLocation && currentLocation.location !== "") {
      setBottomNavStatus(currentLocation.location)
    } else {
      setBottomNavStatus("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const locationMap = [
    { path: "/", location: "sign-in" },
    { path: "/dashboard", location: "dashboard" },
    { path: "/gallery", location: "gallery" },
    { path: "/reviews", location: "reviews" },
    { path: "/reviews/invite", location: "review-invite" },
    { path: "/reviews/video", location: "video-review" },
    { path: "/reviews/written", location: "written-review" },
    { path: "/chat", location: "chat" },
    { path: "/statistics", location: "stats" },
    { path: "/settings", location: "settings" },
  ]

  return (
    <Paper className={classes.bottomPaper}>
      <div className={classes.container}>
        <BottomNavigation
          value={bottomNavStatus}
          className={classes.bottomNavigation}
        >
          <BottomNavBtn
            value="dashboard"
            icon={
              pathname === "/dashboard" ? (
                <ActiveHomeSVG />
              ) : (
                <InactiveHomeSVG />
              )
            }
            to="/dashboard"
            component={RouterLink}
          />
          <Box sx={{ flexGrow: 1.75 }} />
          <BottomNavBtn
            value="settings"
            icon={
              pathname === "/settings" ? (
                <ActiveSettingsSVG />
              ) : (
                <InactiveSettingsSVG />
              )
            }
            to="/settings"
            component={RouterLink}
          />
        </BottomNavigation>
      </div>
    </Paper>
  )
}
