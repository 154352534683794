import React, { createContext, useState } from "react";

export const AppContext = createContext();

// This context provider is passed to any component requiring the context
export const AppProvider = ({ children }) => {
  const [reviewID, setReviewID] = useState(-1);
  const [PRVideoCount, setPRVideoCount] = useState(0);
  const [PRWrittenCount, setPRWrittenCount] = useState(0);

  const [isSafari, setSafari] = useState(false);

  return (
    <AppContext.Provider
      value={{
        reviewID,
        PRVideoCount,
        PRWrittenCount,
        isSafari,
        setReviewID,
        setPRVideoCount,
        setPRWrittenCount,
        setSafari,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};